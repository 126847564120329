import ApiResource from '../apiResource';
import ApiMethod from '../apiMethod';

module.exports = class Search extends ApiResource {
    constructor(api) {
        super(api);

        this.publicCollections = ApiMethod({
            method: 'GET',
            function: 'search_public_collections',
            params: {
                param1: '',
                param2: '',
                param3: '"asc"',
                param4: '',
                param5: ''
            }
        })

        //NOTE! ['param1'] category must be in the format `!collection${collection.ref}`
        this.searchByCollection = ApiMethod({
            method: 'GET',
            function: 'do_search',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: '',
                param3: '',
                param4: '',
                param5: '',
                param6: ''
            }
        })

        
    }
}