import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteDbAndReload } from '../../../helpers/deleteDbAndReload';
import PrimaryNavigation from '../primary-nav/component';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../../theme-context';
import { beginSync } from '../../loader/actions';

const mapStateToProps = state => ({
    application: state.Application
});

const isKiosk = process.kiosk;
class Header extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showNav: false
        };

        this.toggleNav = () => {
            this.setState({
                showNav: !this.state.showNav
            });
        };

        this.onSyncClick = this.onSyncClick.bind(this);
    }
    onSyncClick(e) {
        let { dispatch } = this.props;
        dispatch(beginSync());
    }

    renderHeader() {
        let {application } = this.props;
        let { theme } = this.context;
        switch (application.currentClient) {
            case 'amf':
                return (
                    <header id="primary-header">
                    <div id="primary-header-menu">
                        <PrimaryNavigation />
                    </div>
                    <div id="primary-header-logo">
                        <Link to='/'>
                            <img src="./img/logo-amf.png" />
                        </Link>
                    </div>
                    <div id="primary-header-search">
                        <Link to='/search'>
                            <img src="./img/ico-search.svg" />
                        </Link>
                    </div>
                </header>)
            case 'rbs':
                // This variable is set at the time of rendering and does not update with resize event, need this to update.
                // https://stackoverflow.com/questions/46586165/react-conditionally-render-based-on-viewport-size
                const mobile = window.innerWidth < 768;
                return (
                <header id="rbs-primary-header">
                <div id="rbs-primary-header-menu">
                    <PrimaryNavigation />
                </div>
                <div id="rbs-primary-header-logo">
                    <Link to='/'>
                        <img src="./img/logo-rbs.png" />
                    </Link>
                </div>
                <div className = "search-sync-container margin-right">
                    {!isKiosk && (mobile ?
                        (<button type="button" className={`sync-btn sync-btn--${theme} btn btn-square btn-md`} onClick={this.onSyncClick}>
                            <img className="sync-btn-icon" src="img/rbs-sync-icon.svg"/>
                                {/* NO TEXT ON MOBILE */}
                        </button>)
                    :
                        <div className='search-sync-container log-out'>
                        <button type="button" className={`sync-btn sync-btn--${'rbs'} btn btn-square btn-md log-out`} onClick={() => deleteDbAndReload()}>
                            LOG OUT
                        <img className="sync-btn-icon log-out" src="img/log-out-round.svg"/>
                        </button>
                    </div>)
                    }
                    {/* Original Code preserved as example */}
                    {/* <button type="button" className={`sync-btn sync-btn--${theme} btn btn-square btn-md`} onClick={this.onSyncClick}>
                        <img className="sync-btn-icon" src="img/rbs-sync-icon.svg"/>
                            UPDATE CONTENT
                        </button> */}
                    <div id="rbs-primary-header-search" className="hide-mobile">
                        <Link to='/search'>
                            <img src="./img/rbs-search-icon.svg" />
                        </Link>
                    </div>
                </div>
                </header>)        
        }
    }

    render() {
        return (
           this.renderHeader()
        );
    }
}

export default withRouter(connect(mapStateToProps)(Header));
Header.contextType = ThemeContext;