import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SquareLinkComponent from '../ui/square-link-component';
import BreadcrumbsComponent from '../ui/breadcrumbs'
import BackButtonComponent from '../ui/back-button-component';
import { withRouter } from 'react-router';
import ProcessComponent from '../process/component';
import ErrorUI from '../ui/error/component';
import { ThemeContext } from '../../theme-context';

import { sortByMetadata } from '../../helpers/sort';

const mapStateToProps = state => ({
    application: state.Application
});

class Subsystem extends PureComponent {
    constructor(props) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    componentWillMount() {
        let { match } = this.props;
        this.redirect(match);
    }

    componentDidUpdate(oldProps) {
        if(this.props.match && oldProps.match && this.props.match.params.ref != oldProps.match.params.ref) {
            this.redirect(this.props.match);
        }
    }

    redirect(match) {
        let { history, application } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let redirect = application.redirectRefs.find(r => r.from == ref);
        if(redirect) {
            history.replace(redirect.to);
        }
    } 

    buildBreadcrumbLinks(subsystem) {
        let links = [{
            title: 'Home',
            url: '/'
        }, {
            title: subsystem.name,
            url: `/subsystems/${subsystem.ref}`
        }];
        return links;
    }

    renderProcesses(processes) {
        return (<div className="product-row">
            <div className="product-row-container">
            {processes.map(p => <SquareLinkComponent key={p.ref} title={p.name} uri={`process/${p.ref}`} imageUri={``} classRef={p.ref}  />)}
            </div>
        </div>);
    }

    renderExtraDepth(subsystem, processes) {
        let { theme } = this.context;
        let {application } = this.props;
        return (
            <div>
                <BreadcrumbsComponent links={this.buildBreadcrumbLinks(subsystem)} />

                <div className="inner-width">
                    <BackButtonComponent />
                    <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{subsystem.name}</h1>
                    {this.renderProcesses(processes)}
                </div>
            </div>
        );
    }

    renderNoExtraDepth(subsystem, processes) {
        return (
            <div>
                <ProcessComponent subsystemProp={subsystem} processesProp={processes} />
            </div>
        );
    }

    render() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let subsystem = application.subsystems.filter(s => s.ref == ref)[0];
        let processes = application.subsystems.filter(s => s.parent == ref);
        processes.sort(sortByMetadata);

        switch (application.extraDepth) {
            case true:
                return (
                    this.renderExtraDepth(subsystem, processes)
                )
            case false:
                return (
                    this.renderNoExtraDepth(subsystem, processes)
                )
            default:
                return <ErrorUI />
        }
    }
}

export default withRouter(connect(mapStateToProps)(Subsystem));
Subsystem.contextType = ThemeContext;