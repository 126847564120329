import ApiResource from '../apiResource';
import ApiMethod from '../apiMethod';

module.exports = class Search extends ApiResource {
    constructor(api) {
        super(api);

        this.verify = ApiMethod({
            method: 'GET',
            function: 'verify_user',
            urlParams: ['param1', 'param2'],
            params: {
                param1: '', //username
                param2: '', //password
            }
        })       
    }
}