import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    application: state.Application
});

class BackButtonComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
        e.preventDefault();

        let { history } = this.props;
        history.goBack();
    }

    renderBackButton() {
        let { application } = this.props;
        let { theme } = this.context;
        switch (application.currentClient) {
            case 'amf':
                return (<div className="row">
                    <div className="col-12">
                        <a className={`backbutton backbutton--${theme}`} href="#" onClick={this.goBack}><img src="img/back-carat.svg" className={`back-button-icon back-button-icon--${theme}`} /> Back</a>
                    </div>
                </div>)
            case 'rbs':
                return (<div className="row">
                    <div className="col-12">
                        <a className={`backbutton backbutton--${theme}`} href="#" onClick={this.goBack}><img src="img/rbs-back-caret.svg" className={`back-button-icon back-button-icon--${theme}`} /> Back</a>
                    </div>
                </div>)
        }
    }
    render() {
        let { theme } = this.context;
        return (
            this.renderBackButton()
        );
    }
}

export default withRouter(connect(mapStateToProps)(BackButtonComponent));
BackButtonComponent.contextType = ThemeContext;
