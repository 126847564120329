import fetch from 'node-fetch';

const DEFAULT_HEADERS = {
    'Content-Type': 'application/octet-stream; charset=UTF-8',
    'Accept': 'application/octet-stream',
    //'Accept': 'text/html',
    'Accept-Encoding': 'gzip, deflate',
}

export function checkFilteredEmail(email, cb) {
    let reqObj = {
        //method: "GET",
        headers: DEFAULT_HEADERS
    }
    let url = `${window.location.origin}/checkFilteredEmail/${email}`;
    return fetch(url, reqObj)
    .then(response => response.text())
    .then(body => { cb(body); })
    .catch(e => console.log(e, 'err'));
}