import React, { PureComponent } from 'react';
import { ThemeContext } from '../../../theme-context';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    application: state.Application
});

class LoaderUI extends PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        let {
            loadingPercentage = 0,
            hidePercentageBar = false,
            hideLoadingText = false,
            loadingText = 'Loading...',
            step = 0,
            electron = false
        } = this.props;
        let { theme } = this.context;
        let {application} = this.props;

        switch (application.currentClient) {
            case 'amf':
                return (
                <div className="text-center w-100">
                <div>
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/logo-amf.png" />
                    </div>
                    <div className={`loading-text-and-bar loading-text-and-bar--${theme}`}></div>
                    {step > 0 ? 
                    <div>
                        <h3 className={`loading-text loading-text--${theme}`}>Step {step} of {electron === false ? "3" : "4"}</h3>
                        <h3 className={`loading-text loading-text--${theme}`}>{loadingText}</h3>
                        {!hidePercentageBar && <div className="progress w-25 ml-auto mr-auto"><div style={{"width": `${loadingPercentage}%`}} className={`progress-bar progress-bar--${theme}`} role="progressbar" aria-valuenow={`100`} aria-valuemin="0" aria-valuemax="100"></div></div>}
                    </div>
                    :
                        step === 0 ? 
                        <div>
                            {hideLoadingText ? "" : <h3 className={`loading-text loading-text--${theme}`}>Validating collections...</h3>}
                        </div>
                        : 
                        <div>
                            <h3 className={`loading-text loading-text--${theme}`}>Invalid collections detected. Please resync...</h3>
                            <button type="button" className={`sync-btn sync-btn--${theme} btn btn-square btn-md btn-outline-dark`} onClick={() => this.props.onSyncClick()}>Force sync</button>
                        </div>
                    }
            </div>)
                case 'rbs':
                return (
                <div className="text-center w-100">
                <div>
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/rbs-logo-loader-2.svg" />
                    </div>
                    <div className={`loading-text-and-bar loading-text-and-bar--${theme}`}></div>
                    {step > 0 ? 
                    <div>
                        <h3 className={`loading-text loading-text--${theme}`}>Step {step} of {electron === false ? "3" : "4"}</h3>
                        {/* <h3 className={`loading-text loading-text--${theme}`}>{loadingText}</h3> */}
                        {!hidePercentageBar && <div className="progress w-25 ml-auto mr-auto progress-mobile"><div style={{"width": `${loadingPercentage}%`}} className={`progress-bar progress-bar--${theme}`} role="progressbar" aria-valuenow={`100`} aria-valuemin="0" aria-valuemax="100"></div></div>}
                    </div>
                    :
                        step === 0 ? 
                        <div>
                            {hideLoadingText ? "" : <h3 className={`loading-text loading-text--${theme}`}>Loading Latest Content...</h3>}
                        </div>
                        : 
                        <div>
                            <h3 className={`loading-text loading-text--${theme}`}>New content is available. Please Click “Update Content” to view the most recent information...</h3>
                            <button type="button" className={`btn-primary btn-primary--${theme} btn btn-square btn-md update-content-mobile`} onClick={() => this.props.onSyncClick()}>Update Content</button>
                        </div>
                    }
            </div>)
        }
    }
}

export default withRouter(connect(mapStateToProps)(LoaderUI));
LoaderUI.contextType = ThemeContext;
