//import { API as api_config } from '../../config';
import * as constants from "./constants";
import {emit} from 'eiphop';
import fetch from 'node-fetch';
//var FileSaver = require('file-saver');
var download = require("downloadjs");

//var api = require('../../api_module/api')(api_config.user, api_config.key, api_config.url);

export function RequestAsset(values) {
    emit(constants.SAVE_ASSET_REQUEST, values)
        .then(res => { 
            //console.log(res); 
            return { type: constants.SAVE_ASSET_REQUEST, data: res }
        });
}

export function SaveAsset(url, title) {
    return (dispatch) => {
        if(process.target != 'web') {
            emit(constants.DOWNLOAD_ASSET, {uri: url, title: title })
            .then(res => { 
                console.log(res);
            });
        } else {
            fetch(url).then(res => res.buffer()).then(content => {
                let blob = new Blob([content])
                download(blob, title);
            })
        }
    }
};

