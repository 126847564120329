import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CheckGuestValidated, checkLoggedIn, logIn, sendPasswordlessEmailCode, validatePasswordlessCode } from './actions';
import { ThemeContext } from '../../theme-context';
import BlacklistErrorUI from '../ui/blacklistError/component';

const mapStateToProps = state => ({
    isLoggedIn: state.Application.isLoggedIn,
    loginErrorMessage: state.Application.loginErrorMessage,
    application: state.Application
});

const initialValues = {
    username: '',
    password: '',
    email: '',
    code: ''
};

const validateEmail = (value) => {
    let error;
    if (!value) {
        error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)) {
        error = "Invalid email address";
    }
    return error;
}

const validateCode = (value) => {
    let error;
    if (!value) {
        error = "Required";
    } else if (!/[0-9]{6}/i.test(value)) {
        error = "Please provide the correct verification code.";
    }
    return error;
}

class LoginComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
            isGuestMode: true,
            isGuestModeSubmitted: false,
            isBlacklistedGuest: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onGuestModeSubmit = this.onGuestModeSubmit.bind(this);
        this.handleLoginGuestClick = this.handleLoginGuestClick.bind(this);
        this.handleLoGoBackToUserLogin = this.handleLoGoBackToUserLogin.bind(this);
        this.handleBlacklistFalse = this.handleBlacklistFalse.bind(this);
    }

    componentWillMount() {
        let { dispatch } = this.props;
        dispatch(checkLoggedIn());
    }

    componentDidMount() {
        let { dispatch } = this.props;
        dispatch(CheckGuestValidated(window.location.hash, this.props.history));
    }

    componentDidUpdate() {
        if (this.props.loginErrorMessage && this.state.formSubmitted) {
            this.setState({ formSubmitted: false });
        }
    }

    onSubmit(values, { resetForm }) {
        let { dispatch } = this.props;
        this.setState({ formSubmitted: true });
        dispatch(logIn(values.username, values.password));
    }

    handleLoginGuestClick(e) {
        e.preventDefault();
        this.setState({ isGuestMode: true });
    }

    handleLoGoBackToUserLogin(e) {
        e.preventDefault();
        this.setState({ isGuestMode: false });
    }

    handleLoGoBackToEmail = (e, setFieldValue) => {
        e.preventDefault();
        setFieldValue('code', '');
        this.setState({ isGuestModeSubmitted: false });
    }

    handleBlacklistFalse = () => {
        this.setState({ isBlacklistedGuest: false });
    }

    onGuestModeSubmit(values) {
        let { dispatch } = this.props;
        console.log(values, 'values guest submit');
        sendPasswordlessEmailCode(values.email, (res) => {
            console.log(res, 'res in mode submit');
            if (res.error === 'blacklisted') {
                this.setState({ isBlacklistedGuest: true });
            } else {
                this.setState({ isGuestModeSubmitted: true });
            }
        });
    }

    onCodeValidationSubmit = (values) => {
        let { dispatch } = this.props;
        console.log('validate values', values);
        validatePasswordlessCode(values.email, values.code, (res) => {
            if (!res) {
                this.setState({ isBlacklistedGuest: true })
            }
        });
    }

    goBack(e) {
        e.preventDefault();

        let { history } = this.props;
        history.goBack();
    }

    renderGuestModeForm = (theme, isGuestModeSubmitted) => {
        return ({ errors, touched, setFieldValue, isValidating }) => (
            (isGuestModeSubmitted ? <Form className="contact-form">
                {/* <p className={`mx-auto text-center welcome-message`}>The code will be sent to your email inbox. If you don't see the email, please check your junk mail.</p> */}
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="code">Code</label>
                    <Field
                        name="code"
                        type="text"
                        className="form-control"
                        validate={validateCode}
                    />
                    {errors.code && touched.code && <div className='error-message'>{errors.code}</div>}
                </div>
                <div>
                    <button type="button" className={`btn btn-primary btn-primary--${theme} btn-left float-left w-m-100`} onClick={(e) => this.handleLoGoBackToEmail(e, setFieldValue)}>Return to Email Input</button>
                    <button type="submit" className={`btn btn-primary btn-primary--${theme} float-right w-m-100`}>Validate</button>
                </div>
            </Form> :
                <Form className="contact-form">
                    <div className={`form-group form-group--${theme}`}>
                        {/* <p className={`mx-auto text-center welcome-message w-100vw`}>To access the site, please enter your email address for log-in credentials.</p> */}
                        {/* <p className={`mx-auto text-center welcome-message w-100vw`}>You will receive an email with a verification code shortly.</p> */}
                        <label htmlFor="email">Email</label>
                        <Field
                            name="email"
                            type="text"
                            className="form-control"
                            validate={validateEmail}
                        />
                        {errors.email && touched.email && <div className='error-message'>{errors.email}</div>}
                    </div>
                    <div className='d-flex flex-column'>
                        <button type="submit" className={`btn btn-primary btn-primary--${theme} float-right w-100`}>Submit</button>
                        <hr className='button-line-break'></hr>
                        <button type="button" className={`btn btn-primary btn-primary--${theme} btn-left float-left w-100`} onClick={this.handleLoGoBackToUserLogin}>RBS Sales Team Access</button>
                    </div>
                </Form>
            ))
    }

    renderForm(initialValues, onSubmit, onGuestModeSubmit, isSubmitting) {
        let { theme } = this.context;
        let { isGuestMode, isGuestModeSubmitted } = this.state;
        return (<Formik
            initialValues={initialValues}
            onSubmit={isGuestMode ? isGuestModeSubmitted ? this.onCodeValidationSubmit : onGuestModeSubmit : onSubmit}>
            {isGuestMode ? this.renderGuestModeForm(theme, isGuestModeSubmitted) : <Form className="contact-form">
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="username">Username</label>
                    <Field
                        name="username"
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="password">Password</label>
                    <Field
                        name="password"
                        type="password"
                        className="form-control"
                    />
                </div>
                {isSubmitting
                    ? (<button type="submit" disabled={true} className={`btn btn-primary btn-primary--${theme} float-right`}>Verifying...</button>)
                    : (<div>
                        <button type="button" className={`btn btn-primary btn-primary--${theme} btn-left float-left w-m-100`} onClick={this.handleLoginGuestClick}>Guest Access</button>
                        <button type="submit" className={`btn btn-primary btn-primary--${theme} float-right w-m-100`}>Log In</button>
                    </div>)
                }
            </Form>
            }
        </Formik>);
    }
    renderLogInIcon() {
        let { application } = this.props;
        let { theme } = this.context;
        switch (application.currentClient) {
            case 'amf':
                return (<div>
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/logo-amf.png" />
                </div>)

            case 'rbs':
                return (<div>
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/rbs-logo-loader-2.svg" />
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme} infoLink`} src="./img/RBSInfoLink_Logo.png" />
                    <p className={`mx-auto text-center welcome-message`}>Welcome to RBS InfoLink, your online resource for all Reading Bakery Systems sales and marketing information. This application is best experienced on your Desktop or a tablet device.</p>
                </div>)
        }
    }

    render() {
        let { loginErrorMessage } = this.props;
        let { formSubmitted, isBlacklistedGuest, isGuestMode, isGuestModeSubmitted } = this.state;

        return (
            <div>
                {isBlacklistedGuest ? <BlacklistErrorUI setBlacklistFalse={this.handleBlacklistFalse} /> :
                    <div className="inner-width2">
                        {this.renderLogInIcon()}
                        <div className='mt-5'>
                            {isGuestMode &&
                                (isGuestModeSubmitted ?
                                    <p className={`mx-auto text-center welcome-message`}>The code will be sent to your email inbox. If you don't see the email, please check your junk mail.</p>
                                    :
                                    <div>
                                        <p className={`mx-auto text-center welcome-message w-100vw`}>To access the site, please enter your email address for log-in credentials.</p>
                                        <p className={`mx-auto text-center welcome-message w-100vw`}>You will receive an email with a verification code shortly.</p>
                                    </div>)
                            }
                        </div>

                        <div className="login-row">
                            <div className="login-box">
                                {loginErrorMessage && (<div className="error-message">{loginErrorMessage}</div>)}
                                {this.renderForm(initialValues, this.onSubmit, this.onGuestModeSubmit, formSubmitted)}
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(LoginComponent));
LoginComponent.contextType = ThemeContext;