import { API as api_config, MetadataMapping } from '../../config';
import * as db from '../../helpers/indexDB';
import { checkFilteredEmail } from '../../helpers/checkFilteredEmail';
import * as app_constants from '../application/constants';
import { emit, setupMainHandler } from 'eiphop';
import auth0 from 'auth0-js';

// get corresponding user and key;
var apiUser, apiKey, api;

function setApi(guestMode) {
    if (guestMode) {
        apiUser = api_config.guestUsername;
        apiKey = api_config.guestKey;
        api = require('../../api_module/api')(apiUser, apiKey, api_config.url);
    } else {
        apiUser = api_config.user;
        apiKey = api_config.key;
        api = require('../../api_module/api')(apiUser, apiKey, api_config.url);
    }

    return Promise.resolve();
}

// Default init the api / do not reset api if redirected by auth0 with access_token
if (!window.location.hash.includes("access_token"))
db.getAllItemsFromStore(db.Constants.STORE_USER).then(users => {
    if (!users) { users = [] }
    if ((users.length != 0 && users[0].guestMode)) {
        setApi(true);
    }
    else {
        setApi(false);
    }
});

let chunkSize = 10;

var webAuth = new auth0.WebAuth({
    domain: api_config.auth0Domain,
    clientID: api_config.auth0ClientID,
    redirectUri: api_config.auth0RedirectUrl,
    responseType: api_config.auth0ResponseType
})

export function sendPasswordlessEmailCode(email, cb) {
    checkFilteredEmail(email, (result) => {
        if (result !== null) console.log(result);
        console.log(result === true, 'true type')
        if (result === 'true') return cb({ error: 'blacklisted' });

        webAuth.passwordlessStart({
            connection: 'email',
            send: 'code',
            email: email,
        }, function (err, res) {
            if (err) {
                // handle err.
                return cb(err);
            } else {
                return cb(res);
            }
        })
    })
}

export function validatePasswordlessCode(email, code, cb) {
    webAuth.passwordlessLogin({
        connection: 'email',
        email: email,
        verificationCode: code
    }, function (err, res) {
        if (typeof cb !== 'function') return;
        if (err) {
            return cb(false);
        } else {
            return cb(res);
        }
    })
}

export function CheckGuestValidated(windowHash, history) {
    return (dispatch) => {
        if (windowHash && !windowHash.includes('access_token')) return;
        return webAuth.parseHash({ hash: windowHash }, function (err, authResult) {
            if (err) {
                return console.log(err);
            }
            console.log('actionResult', authResult);

            return webAuth.client.userInfo(authResult.accessToken, function (err, user) {
                if (user && user.email_verified) {
                    dispatch(guestLogIn(history));
                }
            });
        });
    }
}

function addValuesIfNotExists(storeName, newValues) {
    newValues.map(nv => {
        db.getItemFromStore(storeName, nv.ref).then(item => {
            if (!item) {
                db.addToStore(storeName, nv);
            }
        });
    })
}

function upsertValues(storeName, newValues) {
    newValues.map(nv => {
        db.getItemFromStore(storeName, nv.ref).then(item => {
            if (item) {
                db.updateInStore(storeName, nv);
            } else {
                db.addToStore(storeName, nv);
            }
        });
    })
}

function difference(a1, a2) {
    var a2Set = new Set(a2);
    return a1.filter(function (x) { return !a2Set.has(x); });
}

function cleanUpRefs(storeName, prevValues, newValues) {
    let pvRefs = prevValues.map(pv => pv.ref);
    let nvRefs = newValues.map(nv => nv.ref);
    let refsToDelete = difference(pvRefs, nvRefs);
    refsToDelete.map(rk => {
        db.deleteFromStore(storeName, rk);
        let idx = nvRefs.indexOf(rk);
        newValues.splice(idx, 1);
    })
}
/*
    BEGIN LOADING METADATA
*/
function loadMetadata_Generic(forceUpdate, metadataFieldId, databaseStoreName, onCompleteAction) {
    return (dispatch) => {
        return db.getAllItemsFromStore(databaseStoreName).then(metadata => {
            if (!metadata) { metadata = []; }
            if (metadata.length == 0 || forceUpdate) {
                api.metadata.getByFieldId(metadataFieldId)
                    .then(res => {
                        upsertValues(databaseStoreName, res);
                        cleanUpRefs(databaseStoreName, metadata, res);
                        dispatch(setMetadata_Array(onCompleteAction, res));
                    })
                    .catch(err => {
                        dispatch(setMetadata_Array(onCompleteAction, []));
                        console.log(err);
                    })
            } else {
                dispatch(setMetadata_Array(onCompleteAction, metadata));
            }
        })
    }
}

export function loadAllMetadata(forceUpdate, metadataTypes) {
    return (dispatch) => {
        try {
            metadataTypes.forEach(mt => {
                if (mt) {
                    const storeName = `metadata_${mt.shorthand_name.replace('-', '')}`;
                    const actionName = `SET_METADATA_${mt.shorthand_name.replace('-', '').toUpperCase()}`;
                    dispatch(loadMetadata_Generic(forceUpdate, mt.id, storeName, actionName));
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
}

function checkMetadata_ProductSystemSolutions() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_SOLUTIONS);
    return Promise.all([promise]).then(solutions => {
        if (!solutions) { solutions = [] }
        if (solutions[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkMetadata_ProductBrands() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_BRANDS);
    return Promise.all([promise]).then(brands => {
        if (!brands) { brands = [] }
        if (brands[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkMetadata_SubSystems() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_SUBSYSTEMS);
    return Promise.all([promise]).then(subsystems => {
        if (!subsystems) { subsystems = [] }
        if (subsystems[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkMetadata_Categories() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_CATEGORIES);
    return Promise.all([promise]).then(categories => {
        if (!categories) { categories = [] }
        if (categories[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkMetadata_Models() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_MODELS);
    return Promise.all([promise]).then(models => {
        if (!models) { models = [] }
        if (models[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkMetadata_UnitEquipments() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_UNITEQIPMENTS);
    return Promise.all([promise]).then(unitEqs => {
        if (!unitEqs) { unitEqs = [] }
        if (unitEqs[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkCollections() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_COLLECTIONS);
    return Promise.all([promise]).then(collections => {
        if (!collections) { collections = [] }
        if (collections[0].length == 0) {
            return true;
        }
        return false;
    })
}

function checkResources() {
    let promise = db.getAllItemsFromStore(db.Constants.STORE_RESOURCES);
    return Promise.all([promise]).then(resources => {
        if (!resources) { resources = [] }
        if (resources[0].length == 0) {
            return true;
        }
        return false;
    })
}

export const checkAllMetadata = async (validation) => {
    let solutions = false;
    let brands = false;
    let subsystems = false;
    let categories = false;
    let models = false;
    let uniteqs = false;

    if (validation !== undefined) {
        if (validation.validateProductSystemSolutions) {
            solutions = await checkMetadata_ProductSystemSolutions();
        }
        if (validation.validateBrands) {
            brands = await checkMetadata_ProductBrands();
        }
        if (validation.validateSubsystems) {
            subsystems = await checkMetadata_SubSystems();
        }
        if (validation.validateCategories) {
            categories = await checkMetadata_Categories();
        }
        if (validation.validateModels) {
            models = await checkMetadata_Models();
        }
        if (validation.validateUnitEquipments) {
            uniteqs = await checkMetadata_UnitEquipments();
        }
    }

    return solutions || brands || subsystems || categories || models || uniteqs ? true : false;
}

function setMetadata_Array(type, data) {
    return {
        type: type,
        data: data
    }
}

/*
    END LOADING METADATA
*/

/*
    BEGIN LOADING COLLECTIONS
*/
export function loadCollections(forceUpdate) {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_COLLECTIONS).then(collections => {
            if (!collections) { collections = [] }
            if (collections.length == 0 || forceUpdate) {
                api.search.publicCollections()
                    .then(res => {
                        upsertValues(db.Constants.STORE_COLLECTIONS, res);
                        cleanUpRefs(db.Constants.STORE_COLLECTIONS, collections, res);
                        dispatch(setCollections(res, forceUpdate));
                    })
                    .catch(err => {
                        dispatch(setCollections([], forceUpdate));
                        console.log(err);
                    })
            } else {
                dispatch(setCollections(collections, forceUpdate));
            }
        });
    }
}

function loadCollectionResources(collectionRef, forceUpdate, failures = 0) {
    return (dispatch) => {
        let collectionPromise = db.getItemFromStore(db.Constants.STORE_COLLECTIONS, collectionRef);
        let resourcesPromise = db.getAllItemsFromStore(db.Constants.STORE_RESOURCES);
        let unitEquipmentMetadataPromise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_UNITEQIPMENTS);
        return Promise.all([collectionPromise, resourcesPromise, unitEquipmentMetadataPromise]).then(values => {
            let collection = values[0];
            let resources = values[1];
            let unitEquipments = values[2];
            if (!resources) { resources = [] }
            if (resources.length == 0 || forceUpdate) {
                let loweredCollectionName = collection.name.toLowerCase();
                let unitEquipmentForCollection = unitEquipments.find(ue => ue.name.toLowerCase() == loweredCollectionName);
                let searchPromises = [api.search.searchByCollection(`!collection${collectionRef}`)];
                if (unitEquipmentForCollection) {
                    searchPromises.push(api.search.searchByCollection(`@@${unitEquipmentForCollection.ref}`));
                }
                Promise.all(searchPromises).then(searchValues => {
                    let resourceList = [];
                    searchValues[0].map(sv => {
                        sv.last_synced = new Date();
                        resourceList.push(sv);
                    });
                    if (searchValues[1]) {
                        searchValues[1].map(sv => {
                            if (!resourceList.find(rr => rr.ref == sv.ref)) {
                                sv.last_synced = new Date();
                                resourceList.push(sv);
                            }
                        })
                    }
                    let resourceRefs = resourceList.map(r => r.ref);
                    collection.resourceRefs = resourceRefs;
                    db.updateInStore(db.Constants.STORE_COLLECTIONS, collection);
                    upsertValues(db.Constants.STORE_RESOURCES, resourceList);
                    dispatch(updateCollection(collection, forceUpdate));
                })
                    .catch(err => {
                        console.log(err);
                        if (failures < 10) {
                            dispatch(loadCollectionResources(collectionRef, forceUpdate, failures + 1))
                        } else {
                            dispatch(updateCollection(collection, forceUpdate));
                        }
                    })
            } else {
                dispatch(updateCollection(collection, forceUpdate));
            }
        });
    }
}

function loadNextCollection(prevRef, forceUpdate) {
    return (dispatch) => {
        db.getAllItemsFromStore(db.Constants.STORE_COLLECTIONS).then(collections => {
            let nextIndex = 0
            if (prevRef != null) {
                nextIndex = collections.findIndex(c => c.ref == prevRef) + 1;
            }
            if (nextIndex >= collections.length) {
                console.log("no collections left");
                dispatch(collectionsComplete());
                return;
            }
            else {
                dispatch({
                    type: app_constants.INCREMENT_COUNTER
                });
                dispatch(loadCollectionResources(collections[nextIndex].ref, forceUpdate))
            }
        })
    }
}

function setCollections(data, forceUpdate) {
    return (dispatch) => {
        dispatch({
            type: app_constants.SET_COLLECTIONS,
            data: data
        });
        setTimeout(() => dispatch(loadNextCollection(null, forceUpdate)), 240);
    }
}

function updateCollection(data, forceUpdate) {
    return (dispatch) => {
        dispatch({
            type: app_constants.UPDATE_COLLECTION,
            data: data
        });
        setTimeout(() => dispatch(loadNextCollection(data.ref, forceUpdate)), 10);
    }
}

export const checkAllCollections = async () => {
    return await checkCollections();
}

/*
    END LOADING COLLECTIONS
*/

/*
    BEGIN LOADING RESOURCES
*/
export function loadAllResources(forceUpdate) {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_RESOURCES).then(resources => {
            dispatch(setResources(resources, forceUpdate));
        })
    }
}

export function loadResourceData(resourceRef, forceUpdate, nextChunkOnComplete, failures = 0) {
    return (dispatch) => {
        return db.getItemFromStore(db.Constants.STORE_RESOURCES, resourceRef).then(resource => {
            if (!resource.fields || forceUpdate) {
                let resourceFieldPromise = api.resources.getResourceFieldData(resource.ref);
                let resourceThmUrlPromise = api.resources.getResourcePath(resource.ref, false, 'thm');
                let resourceScrUrlPromise = api.resources.getResourcePath(resource.ref, false, 'pre');
                let resourceOrgUrlPromise = null;
                // might be able to use preview versions for non-movies (smaller file sizes)
                if (resource.resource_type == '3') {
                    resourceOrgUrlPromise = api.resources.getResourcePath(resource.ref, false, 'pre', 'mp4');
                } else {
                    resourceOrgUrlPromise = api.resources.getResourcePath(resource.ref, false, '', resource.file_extension);
                }
                Promise.all([resourceFieldPromise, resourceThmUrlPromise, resourceScrUrlPromise, resourceOrgUrlPromise])
                    .then(values => {
                        //Validate if we actually need to update resource
                        let needsUpdate = false;
                        let newDateModified = new Date(values[0].file_modified);
                        let oldDateModified = new Date(resource.file_modified);
                        if (newDateModified > oldDateModified || !resource.fields) {
                            needsUpdate = true;
                        }

                        if (needsUpdate) {
                            resource.fields = values[0].map(f => {
                                return {
                                    value: f.value,
                                    resource_type_field: f.resource_type_field,
                                    ref: f.ref,
                                    name: f.name,
                                    title: f.title
                                }
                            });
                            resource.url_thm = values[1];
                            resource.url_scr = values[2];
                            resource.url_org = values[3];
                            db.updateInStore(db.Constants.STORE_RESOURCES, resource);
                        }
                        dispatch(updateResource(resource, forceUpdate, nextChunkOnComplete));
                    }).catch(err => {
                        console.log(err);
                        if (failures < 10) {
                            dispatch(loadResourceData(resourceRef, forceUpdate, nextChunkOnComplete, failures + 1))
                        } else {
                            if (!resource.fields) { resource.fields = []; }
                            dispatch(updateResource(resource, forceUpdate, nextChunkOnComplete));
                        }
                    })
            } else {
                if (!resource.fields) { resource.fields = []; }
                dispatch(updateResource(resource, forceUpdate, nextChunkOnComplete));
            }
        });

    }
}

function loadNextResourceChunk(lastRef, forceUpdate) {
    return (dispatch) => {
        db.getAllItemsFromStore(db.Constants.STORE_RESOURCES).then(resources => {
            let nextChunkIndex = 0;
            if (lastRef != null) {
                nextChunkIndex = resources.findIndex(r => r.ref == lastRef) + 1;
            }
            if (nextChunkIndex >= resources.length) {
                console.log("no resources left");
                dispatch(resourcesComplete());
                if (forceUpdate) { dispatch(cleanUpResources()); }
                return;
            }
            else {
                let nextChunk = resources.slice(nextChunkIndex, nextChunkIndex + chunkSize);
                nextChunk.map((r, i) => {
                    dispatch(loadResourceData(r.ref, forceUpdate, i == (nextChunk.length - 1)));
                })
            }
        })
    }
}

function setResources(data, forceUpdate) {
    return (dispatch) => {
        dispatch({
            type: app_constants.SET_RESOURCES,
            data: data
        });
        setTimeout(() => dispatch(loadNextResourceChunk(null, forceUpdate)), 500);
    }
}

function updateResource(data, forceUpdate, nextChunkOnComplete) {
    return (dispatch) => {
        dispatch({
            type: app_constants.UPDATE_RESOURCE,
            data: data
        });
        dispatch({
            type: app_constants.INCREMENT_COUNTER
        });
        if (nextChunkOnComplete)
            setTimeout(() => dispatch(loadNextResourceChunk(data.ref, forceUpdate)), 10);
    }
}

// function deleteResource(data, forceUpdate, nextChunkOnComplete) {
//     return (dispatch) => {
//         dispatch({
//             type: app_constants.DELETE_RESOURCE,
//             data: data
//         });
//         if (nextChunkOnComplete)
//         setTimeout(() => dispatch(loadNextResourceChunk(data.ref, forceUpdate)), 10);
//     }
// }

function cleanUpResources() {
    //If any resources didn't have their last_synced time within 1 hour, delete
    let oldDate = new Date();
    oldDate.setHours(oldDate.getHours() - 1);
    db.getAllItemsFromStore(db.Constants.STORE_RESOURCES).then(resources => {
        let oldResources = resources.filter(r => !r.last_synced || r.last_synced < oldDate);
        oldResources.map(r => { db.deleteFromStore(db.Constants.STORE_RESOURCES, r.ref); });
    });
    return {
        type: app_constants.CLEAN_RESOURCE,
        data: oldDate
    };
}

function collectionsComplete() {
    return {
        type: app_constants.COLLECTIONS_COMPLETE,
        data: null
    };
}

function resourcesComplete() {
    return {
        type: app_constants.RESOURCES_COMPLETE,
        data: null
    };
}

export const checkAllResources = async () => {
    return await checkResources();
}

export const checkAssets = async () => {
    return await emit(app_constants.CHECK_TEMP_FOLDER, { resources: null })
        .then(res => {
            return res.result;
        });
}

/*
    END LOADING RESOURCES
*/

/*
    ELECTRON ONLY! BEGIN LOADING ASSETS
*/

export function loadAssets() {
    return (dispatch, getState) => {
        let state = getState();
        let resources = state.Application.resources.slice(0);
        let numChunks = Math.ceil(resources.length / chunkSize);
        let resourceChunks = [];
        for (let i = 0; i < numChunks; i++) {
            let start = i * chunkSize;
            let end = (((i + 1) * chunkSize));
            let resourcesForChunk = resources.slice(start, end);
            resourceChunks.push(resourcesForChunk);
        }

        dispatch({ type: app_constants.SET_ASSETS, data: resourceChunks });
        dispatch({ type: app_constants.SET_ASSET_BATCH_COUNT, data: resourceChunks.length });
        setTimeout(() => dispatch(loadNextAssetChunk()), 500);
    }
}

export function loadNextAssetChunk() {
    return (dispatch, getState) => {
        dispatch({ type: app_constants.SET_ASSET_CURRENT_BATCH });

        let state = getState();
        if (state.Application.assetChunksToLoad.length == 0) { return; }
        let resourceChunks = state.Application.assetChunksToLoad.slice(0);
        let resourceChunk = resourceChunks[0];
        emit(app_constants.LOAD_ASSETS, { resources: resourceChunk })
            .then(res => {
                if (res.message) {
                    alert(`${res.message} Error: ${typeof res.error === "object" ? JSON.stringify(res.error) : 'Server Error'}`)
                }
                if (res.resources) {
                    res.resources.map(r => {
                        //Update local asset locations
                        db.updateInStore(db.Constants.STORE_RESOURCES, r);
                        dispatch(updateResource(r));
                    });
                }
                resourceChunks.splice(0, 1);
                dispatch({ type: app_constants.SET_ASSETS, data: resourceChunks });
                setTimeout(() => dispatch(loadNextAssetChunk()), 240);
            });
    }
}

/*
    END LOADING ASSETS
*/

export function completeLoading() {
    return {
        type: app_constants.COMPLETE_LOADING,
        data: null
    }
}

export function beginSync() {
    return {
        type: app_constants.BEGIN_SYNC,
        data: null
    };
}

/* 
    BEGIN USER LOGIN ACTIONS
*/

export function checkLoggedIn() {
    return (dispatch) => {
        let localMetadataMapping = {};
        if (process.vendor === 'amf') {
            console.log('amf');
            localMetadataMapping = MetadataMapping.clients.filter(t => t.name == "amf")[0];
        } else if (process.vendor === 'rbs') {
            console.log('rbs');
            localMetadataMapping = MetadataMapping.clients.filter(t => t.name == "rbs")[0];
        }
        dispatch(setMetadataMapping(
            localMetadataMapping.name,
            localMetadataMapping.metaDataToLoad,
            localMetadataMapping.validation,
            localMetadataMapping.metadata,
            localMetadataMapping.unitEquipmentRefsToHide,
            localMetadataMapping.set2SubsystemRefs,
            localMetadataMapping.refsToHideAssets,
            localMetadataMapping.resourceTypes,
            localMetadataMapping.extraDepth,
            localMetadataMapping.redirectRefs,
            localMetadataMapping.singleLevelRefs,
            localMetadataMapping.prodAccentImgs,
            localMetadataMapping.prodDetailLinks,
            localMetadataMapping.prodDetailRefs
        ))
        return db.getAllItemsFromStore(db.Constants.STORE_USER).then(users => {
            if (!users) { users = [] }
            if (users.length == 0) {
                dispatch(setLoggedIn(false));
            } else {
                dispatch(setLoggedIn(true));
                if (users[0].guestMode) {
                    dispatch(setGuestMode(true));
                }
            }
        });
    }
}

export function logIn(username, password) {

    return (dispatch) => {
        return setApi(false).then(x => {
            if (username.toLowerCase() === "guest") {
                dispatch(setLoggedIn(false, "Something Went Wrong."));
                return;
            }
            api.user.verify(username, password).then(user => {
                if (user && user.valid) {
                    db.updateInStore(db.Constants.STORE_USER, { ref: '0', valid: 'true' });
                    dispatch(setLoggedIn(true));
                } else {
                    dispatch(setLoggedIn(false, "User Not Valid."));
                }
            }).catch(e => {
                console.log('failed login', e);
                dispatch(setLoggedIn(false, "Something Went Wrong."));
            });
        });
    }

}

export function guestLogIn(history) {
    const { guestUsername, guestPassword } = api_config;
    //api = require('../../api_module/api')(api_config.guestUsername, api_config.guestKey, api_config.url);
    return (dispatch) => {
        return setApi(true).then(x => {
            api.user.verify(guestUsername, guestPassword).then(user => {
                if (user && user.valid) {
                    db.updateInStore(db.Constants.STORE_USER, { ref: '0', valid: 'true', guestMode: true });
                    dispatch(setLoggedIn(true));
                    dispatch(setGuestMode(true));
                } else {
                    dispatch(setLoggedIn(false, "Invalid Username Or Password."));
                }
                history.push('/');
            });
        });
    }
}

export function setLoggedIn(valid, message) {
    return {
        type: app_constants.SET_USER_LOGGEDIN,
        data: { valid: valid, message: message }
    };
}

export function setGuestMode(isGuestMode) {
    return {
        type: app_constants.SET_USER_GUESTMODE,
        data: { isGuestMode: isGuestMode }
    };
}

export function setMetadataMapping(currentClient, metaDataToLoad, validation, metadataMapping, unitEquipmentRefsToHide, set2SubsystemRefs, refsToHideAssets, resourceTypes, extraDepth, redirectRefs, singleLevelRefs, prodAccentImgs, prodDetailLinks, prodDetailRefs) {
    return {
        type: app_constants.SET_METADATA_MAPPING,
        data: {
            currentClient: currentClient,
            metaDataToLoad: metaDataToLoad,
            validation: validation,
            metadataMapping: metadataMapping,
            unitEquipmentRefsToHide: unitEquipmentRefsToHide,
            set2SubsystemRefs: set2SubsystemRefs,
            refsToHideAssets: refsToHideAssets,
            resourceTypes: resourceTypes,
            extraDepth: extraDepth,
            redirectRefs: redirectRefs,
            singleLevelRefs: singleLevelRefs,
            prodAccentImgs: prodAccentImgs,
            prodDetailLinks: prodDetailLinks,
            prodDetailRefs: prodDetailRefs
        }
    }
}

/* 
    END USER LOGIN ACTIONS
*/

/*
    BEGIN DATABASE LOADING
*/

export function loadEverything(electron) {
    return (dispatch) => {
        dispatch(loadMetadata_ProductSystemSolutionsDb());
        dispatch(loadMetadata_ProductBrandsDb());
        dispatch(loadMetadata_SubSystemsDb());
        dispatch(loadMetadata_CategoriesDb());
        dispatch(loadMetadata_ModelsDb());
        dispatch(loadMetadata_UnitEquipmentsDb());
        dispatch(loadMetadata_TechnicalFileTypesDb());
        dispatch(loadMetadata_PhotoGroupDb());

        dispatch(loadCollectionsDb());
        dispatch(loadResourcesDb());

        if (electron) {
            dispatch(loadAssetsDb());
        }
    }
}

export function loadMetadata_ProductSystemSolutionsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_SOLUTIONS).then(solutions => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_SOLUTIONS, solutions));
        })
    }
}

export function loadMetadata_ProductBrandsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_BRANDS).then(brands => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_BRANDS, brands));
        })
    }
}

export function loadMetadata_SubSystemsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_SUBSYSTEMS).then(subsystems => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_SUBSYSTEMS, subsystems));
        })
    }
}

export function loadMetadata_CategoriesDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_CATEGORIES).then(categories => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_CATEGORIES, categories));
        })
    }
}

export function loadMetadata_ModelsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_MODELS).then(models => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_MODELS, models));
        })
    }
}

export function loadMetadata_UnitEquipmentsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_UNITEQIPMENTS).then(unitEqs => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_UNITEQIPMENTS, unitEqs));
        })
    }
}

export function loadMetadata_TechnicalFileTypesDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_TECHNICALFILETYPES).then(tfts => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_TECHNICALFILETYPES, tfts));
        })
    }
}

export function loadMetadata_PhotoGroupDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_METADATA_PHOTOGROUPS).then(photogroups => {
            dispatch(setMetadata_Array(app_constants.SET_METADATA_PHOTOGROUPS, photogroups));
        })
    }
}

export function loadCollectionsDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_COLLECTIONS).then(collections => {
            dispatch(setCollectionsDb(collections));
        });
    }
}

export function loadResourcesDb() {
    return (dispatch) => {
        return db.getAllItemsFromStore(db.Constants.STORE_RESOURCES).then(resources => {
            dispatch(setResourcesDb(resources));
        });
    }
}

function updateResourceDb(data, nextChunkOnComplete) {
    return (dispatch) => {
        dispatch({
            type: app_constants.UPDATE_RESOURCE,
            data: data
        })
        if (nextChunkOnComplete)
            setTimeout(() => dispatch(loadNextResourceChunkDb(data.ref)), 10);
    }
}

function setResourcesDb(data) {
    return (dispatch) => {
        dispatch({
            type: app_constants.SET_RESOURCES,
            data: data
        });
        setTimeout(() => dispatch(loadNextResourceChunkDb(null)), 500);
    }
}

function loadNextResourceChunkDb(lastRef) {
    return (dispatch) => {
        db.getAllItemsFromStore(db.Constants.STORE_RESOURCES).then(resources => {
            let nextChunkIndex = 0;
            if (lastRef != null) {
                nextChunkIndex = resources.findIndex(r => r.ref == lastRef) + 1;
            }
            if (nextChunkIndex >= resources.length) {
                console.log("no resources left");
                dispatch(resourcesComplete());
                return;
            }
            else {
                let nextChunk = resources.slice(nextChunkIndex, nextChunkIndex + chunkSize);
                nextChunk.map((r, i) => {
                    dispatch(loadResourceDataDb(r.ref, i == (nextChunk.length - 1)));
                })
            }
        })
    }
}

export function loadResourceDataDb(resourceRef, nextChunkOnComplete) {
    return (dispatch) => {
        return db.getItemFromStore(db.Constants.STORE_RESOURCES, resourceRef).then(resource => {
            dispatch(updateResourceDb(resource, nextChunkOnComplete));
        });
    }
}

function updateCollectionDb(data) {
    return (dispatch) => {
        dispatch({
            type: app_constants.UPDATE_COLLECTION,
            data: data
        });
        setTimeout(() => dispatch(loadNextCollectionDb(data.ref)), 10);
    }
}

function setCollectionsDb(data) {
    return (dispatch) => {
        dispatch({
            type: app_constants.SET_COLLECTIONS,
            data: data
        });
        setTimeout(() => dispatch(loadNextCollectionDb(null)), 240);
    }
}

function loadNextCollectionDb(prevRef) {
    return (dispatch) => {
        db.getAllItemsFromStore(db.Constants.STORE_COLLECTIONS).then(collections => {
            let nextIndex = 0
            if (prevRef != null) {
                nextIndex = collections.findIndex(c => c.ref == prevRef) + 1;
            }
            if (nextIndex >= collections.length) {
                console.log("no collections left - db");
                dispatch(collectionsComplete());
                return;
            }
            else {
                dispatch(loadCollectionResourcesDb(collections[nextIndex].ref))
            }
        })
    }
}

function loadCollectionResourcesDb(collectionRef) {
    return (dispatch) => {
        let collectionPromise = db.getItemFromStore(db.Constants.STORE_COLLECTIONS, collectionRef);
        let resourcesPromise = db.getAllItemsFromStore(db.Constants.STORE_RESOURCES);
        let unitEquipmentMetadataPromise = db.getAllItemsFromStore(db.Constants.STORE_METADATA_UNITEQIPMENTS);
        return Promise.all([collectionPromise, resourcesPromise, unitEquipmentMetadataPromise]).then(values => {
            let collection = values[0];
            // let resources = values[1];
            // let unitEquipments = values[2];

            dispatch(updateCollectionDb(collection));
        });
    }
}
export function loadAssetsDb() {
    return (dispatch, getState) => {
        let state = getState();
        let resources = state.Application.resources.slice(0);
        let numChunks = Math.ceil(resources.length / chunkSize);
        let resourceChunks = [];
        for (let i = 0; i < numChunks; i++) {
            let start = i * chunkSize;
            let end = (((i + 1) * chunkSize));
            let resourcesForChunk = resources.slice(start, end);
            resourceChunks.push(resourcesForChunk);
        }
        dispatch({ type: app_constants.SET_ASSETS, data: resourceChunks });

        setTimeout(() => dispatch(loadNextAssetChunkDb()), 500);
    }
}

export function loadNextAssetChunkDb() {
    return (dispatch, getState) => {
        let state = getState();
        if (state.Application.assetChunksToLoad.length == 0) { return; }
        let resourceChunks = state.Application.assetChunksToLoad.slice(0);

        dispatch({ type: app_constants.SET_ASSETS, data: resourceChunks });
        setTimeout(() => dispatch(loadNextAssetChunkDb()), 240);
    }
}

/*
    END DATABASE LOADING
*/