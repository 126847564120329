import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

import BreadcrumbsComponent from '../ui/breadcrumbs';
import UnitEquipmentSmallComponent from '../ui/unitequipment-small-component';
import BackButtonComponent from '../ui/back-button-component';

import { sortByMetadata } from '../../helpers/sort';


const mapStateToProps = state => ({
    application: state.Application
});

class ProcessComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            groupings: []
        }
    }

    componentWillMount() {
        let { application } = this.props;

        switch (application.extraDepth) {
            case true:
                this.extraDepth();
                break;
            case false:
                this.noExtraDepth();
                break;
        }
    }

    extraDepth() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let process = application.subsystems.find(s => s.ref == ref);
        let subsystem = application.subsystems.find(s => s.ref == process.parent);
        let groupings = [];
        let unitEquipmentResources = application.resources.filter(r => r.resource_type == "1"
            && r.fields
            && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled")
            && r.fields.find(v => v.name == "sub-system").value.split(',')[0] == subsystem.name
            && r.fields.find(v => v.name == "sub-system").value.split(',')[1] == process.name)
        unitEquipmentResources.map(r => {
            let ueField = r.fields.find(f => f.name == "unitequipment");
            let unitEquipmentMetadata = application.unitequipments.find(r => r.name == ueField.value);
            let categoryField = r.fields.find(v => v.name == "category");
            let modelField = r.fields.find(v => v.name == "model");
            let title = '';
            let groupingSortBy = "0";
            if (categoryField && categoryField.value) { 
                title = categoryField.value; 
                groupingSortBy = application.categories.find(r => r.name == categoryField.value).order_by;
            }
            if (modelField && modelField.value) {
                if (title.length > 0) { title += ' | '; }
                title += modelField.value;
                if(!groupingSortBy)
                    groupingSortBy = application.models.find(r => r.name == modelField.value).order_by;
            }
            let resource = {
                ref: unitEquipmentMetadata ? unitEquipmentMetadata.ref : '',
                name: ueField.value,
                imageUrl: r.url_scr,
                order_by: unitEquipmentMetadata ? unitEquipmentMetadata.order_by : "0"
            }
            let grouping = groupings.filter(g => g.title == title)[0];
            if (grouping) { grouping.resources.push(resource); }
            else { groupings.push({ title: title, resources: [resource], order_by: groupingSortBy }); }
        });
        groupings.sort(sortByMetadata);

        this.setState({
            subsystem: subsystem,
            process: process,
            groupings: groupings
        });
    }

    noExtraDepth() {
        let { application, subsystemProp, processesProp } = this.props;

        let groupings = [];

        processesProp.map(x => {
            let unitEquipmentResources = application.resources.filter(r => r.resource_type == "1"
            && r.fields
            && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled")
            && r.fields.find(v => v.name == "sub-system").value.split(',')[0] == subsystemProp.name
            && r.fields.find(v => v.name == "sub-system").value.split(',')[1] == x.name);

            unitEquipmentResources.map(r => {
                let ueField = r.fields.find(f => f.name == "unitequipment");
                let unitEquipmentMetadata = application.unitequipments.find(r => r.name == ueField.value);
    
                let categoryField = x.name;
                
                let title = '';
                let groupingSortBy = "0";
                if (categoryField) { 
                    title = categoryField;
                }
                let resource = {
                    ref: unitEquipmentMetadata ? unitEquipmentMetadata.ref : '',
                    name: ueField.value,
                    imageUrl: r.url_scr,
                    order_by: unitEquipmentMetadata ? unitEquipmentMetadata.order_by : "0"
                }
                let grouping = groupings.filter(g => g.title == title)[0];
                if (grouping) { grouping.resources.push(resource); }
                else { groupings.push({ title: title, resources: [resource], order_by: groupingSortBy }); }
            });

            this.setState({
                subsystem: subsystemProp,
                process: processesProp,
                groupings: groupings
            });
        });
    }

    buildBreadcrumbLinks(subsystem, process) {
        let links = [{
            title: 'Home',
            url: '/'
        }, {
            title: subsystem.name,
            url: `/subsystems/${subsystem.ref}`
        }];
        if(process && !Array.isArray(process)) {
            links.push({
                title: process.name,
                url: `/subsystems/process/${process.ref}`
            });
        }
        return links;
    }

    renderGroupings(groupings) {
        let { theme } = this.context;
        return groupings.map((g, i) => {
            g.resources.sort(sortByMetadata);
            return (<div key={`g${i}`}>
                {g.title && (
                    <div className="row">
                        <div className="col-12">
                            <h4 className={`category-model-title category-model-title--${theme}`}>{g.title}</h4>
                        </div>
                    </div>
                )}
                <div className="row category-model-row">
                    {g.resources.map(r => { return (<UnitEquipmentSmallComponent key={`ue${r.ref}`} id={r.ref} name={r.name} imageUrl={r.imageUrl} />) })}
                </div>
            </div>)
        })
    }

    render() {
        let { groupings, subsystem, process } = this.state;
        let { application, subsystemProp, processesProp } = this.props;
        let { theme } = this.context;

        return (
            <div>
                {application.extraDepth
                    ?
                    <BreadcrumbsComponent links={this.buildBreadcrumbLinks(subsystem, process)} />
                    :
                    <BreadcrumbsComponent links={this.buildBreadcrumbLinks(subsystemProp, processesProp)} />
                }

                <div className="inner-width">
                    <BackButtonComponent />
                    <div className="row">
                        <div className="col-12">
                            <h4 className={`category-model-page-title category-model-page-title--${theme}`}>
                                {/* override process.name if subsystemProp is provided */}
                                {application.extraDepth === true ? process.name : subsystemProp.name}
                            </h4>
                        </div>
                    </div>
                    {this.renderGroupings(groupings)}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(ProcessComponent));
ProcessComponent.contextType = ThemeContext;