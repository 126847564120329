export function sortByMetadata(a, b) {
    let numA = Number(a.order_by);
    let numB = Number(b.order_by);
    if(numA < numB) { return -1 }
    if(numA > numB) { return 1 }
    return 0;
}

const assetSources = {
    UNITEQUIPMENT: 1,
    PRODUCTS: 2
}

export function sortAssetsByMetadata(validFields, metadataSources) {
    return function (a, b) {
        let aMetadataValue = null;
        let bMetadataValue = null;
        validFields.forEach(vf => {
            if(!aMetadataValue && a.fields.find(af => af.name === vf)) { aMetadataValue = a.fields.find(af => af.name === vf).value }
            if(!bMetadataValue && b.fields.find(bf => bf.name === vf)) { bMetadataValue = b.fields.find(bf => bf.name === vf).value }
        });
        
        let aSortOrder = 0;
        let bSortOrder = 0;
        if(a.fields.find(af => af.name === "sortorder") && b.fields.find(bf => bf.name === "sortorder")) {
            aSortOrder = parseInt(a.fields.find(af => af.name === "sortorder").value);
            if(isNaN(aSortOrder)) { aSortOrder = null; }
            bSortOrder = parseInt(b.fields.find(bf => bf.name === "sortorder").value);
            if(isNaN(bSortOrder)) { bSortOrder = null; }
            
            if(!aMetadataValue && bMetadataValue) return 1;
            else if (!aMetadataValue && !bMetadataValue) {
                if(!aSortOrder && bSortOrder) return 1;
                else if (!bSortOrder && aSortOrder) return -1;
                else if(aSortOrder > bSortOrder) return 1;
                else if (aSortOrder < bSortOrder) return -1;
                return 0;
            }
        }

        let aMetadataTier0SortOrder = null;
        let aMetadataTier1SortOrder = null;
        let bMetadataTier0SortOrder = null;
        let bMetadataTier1SortOrder = null;
        if(aMetadataValue && bMetadataValue) {
            metadataSources.forEach(ms => {
                let [ aTier0, aTier1 ] = aMetadataValue.split(',');
                let [ bTier0, bTier1 ] = bMetadataValue.split(',');
                if(aMetadataValue && !aMetadataTier0SortOrder) { 
                    let msTier0 = ms.find(mf => mf.name === aTier0);
                    if(msTier0) aMetadataTier0SortOrder = parseInt(msTier0.order_by);
                    if (aTier1 && msTier0) {
                        let msTier1 = ms.find(mf => mf.parent === msTier0.ref && mf.name === aTier1);
                        if(msTier1) aMetadataTier1SortOrder = parseInt(msTier1.order_by);
                    }
                }
                if(bMetadataValue && !bMetadataTier0SortOrder) { 
                    let msTier0 = ms.find(mf => mf.name === bTier0);
                    if(msTier0) bMetadataTier0SortOrder = parseInt(msTier0.order_by);
                    if (bTier1 && msTier0) {
                        let msTier1 = ms.find(mf => mf.parent === msTier0.ref && mf.name === bTier1);
                        if(msTier1) bMetadataTier1SortOrder = parseInt(msTier1.order_by);
                    }
                }
            });
        }
        
        if (!aMetadataTier0SortOrder && bMetadataTier0SortOrder || aMetadataTier0SortOrder < bMetadataTier0SortOrder) return -1;
        else if(aMetadataTier0SortOrder > bMetadataTier0SortOrder) return 1;

        if(!aMetadataTier1SortOrder && bMetadataTier1SortOrder || aMetadataTier1SortOrder < bMetadataTier1SortOrder) return -1
        else if( aMetadataTier1SortOrder > bMetadataTier1SortOrder) return 1;
        
        if(!aSortOrder && bSortOrder) return 1;
        else if (!bSortOrder && aSortOrder) return -1;
        else if (aSortOrder > bSortOrder) return 1;
        else if (aSortOrder < bSortOrder) return -1;
        
        return 0;
    }
}