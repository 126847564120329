import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { ThemeContext } from '../../theme-context';

import BreadcrumbsComponent from '../ui/breadcrumbs';
import BackButtonComponent from '../ui/back-button-component';
import Icon1 from '../svg/ImageIcon';
import Icon7 from '../svg/PdfIcon';
import Icon2 from '../svg/BrochureIcon';
import Icon3 from '../svg/VideoIcon';
import Icon6 from '../svg/ProductsIcon';
import Icon8 from '../svg/TechnicalFileIcon';
import Icon9 from '../svg/RbsProduct';
import Collapsible from 'react-collapsible';


import { MetadataMapping } from '../../config';
import { groupBy } from '../../helpers/groupBy';
import { sortAssetsByMetadata } from '../../helpers/sort';
import { getElementsStartsWithId } from '../../helpers/getElementsStartsWithId';

const mapStateToProps = state => ({
    application: state.Application
});

const assetSources = {
    UNITEQUIPMENT: 1,
    PRODUCTS: 2
}

const isKiosk = process.kiosk;

class AssetsComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showEmail: process.kiosk,
            assetSource: assetSources.UNITEQUIPMENT,
            resourceType: "1",
            resourceList: [],
            title: '',
            has3Dpdf: false,
            has2Dpdf: false,
            hasVideo: false,
            hasProducts: false,
            has3Ddocument: false,
            breadcrumbLinks: [],
            groupings: [],
            images: [],
            showBackButton: false,
            toggleCaretDownArrow: false
        }

        this.setResourceType = this.setResourceType.bind(this);
        this.loadAssets_UnitEquipment = this.loadAssets_UnitEquipment.bind(this);
        this.loadAssets_Products = this.loadAssets_Products.bind(this);
        this.buildBreadcrumbLinks_UnitEquipment = this.buildBreadcrumbLinks_UnitEquipment.bind(this);
        this.buildBreadcrumbLinks_Products = this.buildBreadcrumbLinks_Products.bind(this);
        this.handleOnCollapsibleClick = this.handleOnCollapsibleClick.bind(this);
    }

    setResourceType(type) {
        let values = queryString.parse(this.props.location.search);
        values.type = type;
        let valuestring = queryString.stringify(values);
        this.props.history.replace({
            search: valuestring
        });
        this.setState({ resourceType: type, images: [], showBackButton: false });
    }

    componentWillMount() {
        let assetSource = assetSources.UNITEQUIPMENT;
        if (location.hash.indexOf('products') > -1) { assetSource = assetSources.PRODUCTS; }
        let values = queryString.parse(this.props.location.search);
        let resourceType = values.type ? values.type : "1"
        this.setState({
            assetSource: assetSource,
            resourceType: resourceType
        });

        switch (assetSource) {
            case assetSources.PRODUCTS:
                this.loadAssets_Products();
                this.buildBreadcrumbLinks_Products();
                break;
            case assetSources.UNITEQUIPMENT:
            default:
                this.loadAssets_UnitEquipment();
                this.buildBreadcrumbLinks_UnitEquipment();
                break;
        }
    }

    loadAssets_UnitEquipment() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let unitEquipmentMetadata = application.unitequipments.find(m => m.ref == ref);
        let unitEquipmentResources = application.resources.filter(r => r.fields
            && r.fields.find(v => v.name == "unitequipment")
            && r.fields.find(v => v.name == "unitequipment").value.split(',').indexOf(unitEquipmentMetadata.name) > -1);

        // fetch resource types by array index (amf/rbs)
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // technical file

        this.setState({
            title: unitEquipmentMetadata.name,
            resourceList: unitEquipmentResources,
            has3Dpdf: unitEquipmentResources.find(r => r.resource_type == resourceType6.ref) ? true : false,
            has2Dpdf: unitEquipmentResources.find(r => r.resource_type == resourceType2.ref && r.file_extension != 'md') ? true : false,
            hasVideo: unitEquipmentResources.find(r => r.resource_type == resourceType3.ref) ? true : false,
            hasProducts: unitEquipmentResources.find(r => r.resource_type == resourceType5.ref) ? true : false
        });
    }

    loadAssets_Products() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let productMetadata = application.solutions.find(m => m.ref == ref);
        let productCollection = application.collections.find(c => c.name == productMetadata.name);
        let productResources = productCollection
            ? application.resources.filter(r => productCollection.resourceRefs.indexOf(r.ref) > -1)
            : [];

        // fetch resource types by array index (amf/rbs)
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file

        this.setState({
            title: productMetadata.name,
            resourceList: productResources,
            has3Dpdf: productResources.find(r => r.resource_type == resourceType6.ref) ? true : false,
            has2Dpdf: productResources.find(r => r.resource_type == resourceType2.ref && r.file_extension != 'md') ? true : false,
            hasVideo: productResources.find(r => r.resource_type == resourceType3.ref) ? true : false,
            hasProducts: productResources.find(r => r.resource_type == resourceType5.ref) ? true : false
        });
    }

    buildBreadcrumbLinks_UnitEquipment() {
        let { resourceList, title } = this.state;
        if (!title) {
            setTimeout(() => { this.buildBreadcrumbLinks_UnitEquipment() }, 100);
            return;
        }
        let { application, match } = this.props;
        let resource = resourceList.filter(r => r.resource_type == "1" && r.fields.filter(f => f.name == "primaryphoto" && f.value == "Enabled")[0])[0];
        if (!resource) resource = resourceList.filter(r => r.resource_type == "1")[0];

        let links = [{
            title: 'Home',
            url: '/'
        }];

        if (resource) {
            let ref = ((match && match.params) ? match.params.ref : '').toString();
            let singleLevel = application.singleLevelRefs.indexOf(ref) !== -1;

            if (!resource.fields) { resource.fields = []; }
            if (application.extraDepth === true) {
                let subsystemField = resource.fields.filter(v => v.name == "sub-system")[0];
                let processUrl = ''
                if (subsystemField) {
                    let subsystemFieldValues = subsystemField.value.split(',');
                    let subsystem = application.subsystems.find(s => s.parent == '' && s.name == subsystemFieldValues[0]);
                    let process = application.subsystems.find(s => s.parent !== '' && s.name == subsystemFieldValues[1]);
                    if (subsystem) {
                        links.push({
                            title: subsystemFieldValues[0],
                            url: `/subsystems/${subsystem.ref}`
                        })
                    }
                    if (process && process.name) {
                        processUrl = `/subsystems/process/${process.ref}`;
                        links.push({
                            title: subsystemFieldValues[1],
                            url: processUrl
                        })
                    }
                }
                let categoryField = resource.fields.find(v => v.name == "category");
                if (categoryField && categoryField.value) {
                    links.push({
                        title: categoryField.value,
                        url: processUrl
                    });
                }
                let modelField = resource.fields.find(v => v.name == "model");
                if (modelField && modelField.value) {
                    links.push({
                        title: modelField.value,
                        url: processUrl
                    });
                }
                let unitEquipmentField = resource.fields.find(v => v.name == "unitequipment");
                if (unitEquipmentField && unitEquipmentField.value) {
                    let unitEquipment = application.unitequipments.find(ue => ue.name == unitEquipmentField.value);
                    links.push({
                        title: unitEquipmentField.value,
                        url: `/unitequipment/${unitEquipment.ref}`
                    });
                }
            } else {
                let subsystemField = resource.fields.filter(v => v.name == "sub-system")[0];
                let subsystemFieldValues = subsystemField.value.split(',');
                const subsystem = application.subsystems.find(s => s.name == subsystemFieldValues[0]);
                let process = application.subsystems.find(s => s.parent !== '' && s.name == subsystemFieldValues[1]);

                if (!singleLevel && subsystem) {
                    links.push(
                        {
                            title: subsystem.name,
                            url: `/subsystems/${subsystem.ref}`
                        });
                }
                if (!singleLevel && process) {
                    links.push(
                        {
                            title: process.name,
                            url: `/subsystems/${subsystem.ref}`
                        });
                }

                links.push({
                    title: resource.fields.filter(v => v.name == "unitequipment")[0].value,
                    url: `/unitequipment/${ref}`
                });
            }
        }

        this.setState({ breadcrumbLinks: links });
    }

    buildBreadcrumbLinks_Products() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        const product = application.solutions.find(s => s.ref == ref);
        const parentSolution = application.solutions.find(s => s.ref == product.parent);
        const productDetailRef = application.prodDetailRefs ? application.prodDetailRefs.find(r => r === product.ref) : null;

        this.setState({
            breadcrumbLinks: [
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: `${parentSolution.name}`,
                    url: `/solutions/${parentSolution.ref}`
                },
                {
                    title: `${product.name}`,
                    url: productDetailRef ? `/product-detail/${product.ref}/1` : `/products/${product.ref}`
                }
            ],
        });
    }

    renderImage(isActive) {
        let fillColor = isActive ? "#E31B23" : "#000";
        return (

            <Icon1 width={27} height={27} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderPdfImage(isActive) {
        let fillColor = isActive ? "#E31B23" : "#000";
        return (

            <Icon7 width={27} height={27} className={"asset-tab-icon-3d"} fill={fillColor} />)
    }
    renderBrochureImage(isActive) {
        let fillColor = isActive ? "#E31B23" : "#000";
        return (

            <Icon2 width={27} height={27} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderVideoImage(isActive) {
        let fillColor = isActive ? "#E31B23" : "#000";
        return (

            <Icon3 width={35} height={35} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderProductsImage(isActive) {
        let fillColor = isActive ? "#E31B23" : "#000";
        return (

            <Icon6 width={31} height={31} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderRbsImage(isActive) {
        let fillColor = isActive ? "#CC521E" : "#4D361E";
        return (

            <Icon1 width={27} height={27} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderRbsBrochureImage(isActive) {
        let fillColor = isActive ? "#CC521E" : "#4D361E";
        return (

            <Icon2 width={30} height={30} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderRbsVideoImage(isActive) {
        let fillColor = isActive ? "#CC521E" : "#4D361E";
        return (

            <Icon3 width={32} height={32} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderTechnicalFileIcon(isActive) {
        let fillColor = isActive ? "#CC521E" : "#4D361E";
        return (

            <Icon8 width={27} height={27} className={"asset-tab-icon"} fill={fillColor} />)
    }
    renderRbsProduct(isActive) {
        let fillColor = isActive ? "#CC521E" : "#4D361E";
        return (

            <Icon9 width={35} height={33} className={"asset-tab-icon"} fill={fillColor} />)
    }

    renderAssetContainer(resourceType, resources, showEmail, resourceTypes, ref) {
        // add resource idx to each resource to link carousel properly.
        resources.forEach((r, i) => r.rIndex = i);
        let notationText = null;
        if (resourceType == resourceTypes[6].ref) {
            notationText = (
                <p className="asset-reminder"><span className="asset-reminder-note">NOTE:</span> To view a PowerPoint Presentation, you must be on a device with PowerPoint installed. Click on the thumbnail image to access the download link.<br />Then open the downloaded PPTX file in PowerPoint.</p>
            )
        }
        else if (resourceType == resourceTypes[2].ref) {
            notationText = (
                <p className="asset-reminder"><span className="asset-reminder-note">NOTE:</span> Click on the thumbnail image to view the full brochure PDF.</p>
            )
        }
        return (<div>
            {notationText}
            {this.renderAssets(resources, showEmail, resourceTypes, resourceType, ref)}
        </div>)
    }

    renderGroup(group, hidden, groupIndex, startingIndex) {
        let array = Array.isArray(group);
        let { theme } = this.context;

        return (<div key={`ga${groupIndex}-${startingIndex}`} id={`groups-${groupIndex}-${startingIndex}`} className={hidden ? "hidden" : ""}>
            {group.title && (
                <div className="row">
                    <div className="col-12">
                        <h4 className={`category-model-title category-model-title--${theme}`}>{group.titleModified === undefined ? group.title : `${group.titleModified}`}</h4>
                    </div>
                </div>
            )}
            {<div className={`asset-row asset-row--${theme}`}>
                {array ? this.renderAssetGroup(group, this.state.showEmail, startingIndex) : this.renderResources(group, this.state.showEmail, startingIndex)}
            </div>}
        </div>)
    }

    renderGroups(groups, hidden) {
        let array = Array.isArray(groups);
        let { theme } = this.context;
        let html = [];
        let startingIndex = 0;

        groups.forEach((group, i) => {
            if (i > 0) { startingIndex += groups[i - 1].resources.length; };
            let title = group.titleModified === undefined ? group.title : `${group.titleModified + ` | ${group.title}`}`;
            if (title.indexOf(',' > -1)) title = title.split(',').join(': ');

            html.push((
                <div key={`g${i}`} className="dasd">
                    <div id={`groups-${i}`} className={hidden ? "hidden" : ""}>
                        {group.title && (
                            <div className="row">
                                <div className="col-12">
                                    <h4 className={`category-model-title category-model-title--${theme}`}>{title}</h4>
                                </div>
                            </div>
                        )}
                        {<div className={`asset-row asset-row--${theme}`}>
                            {array ? this.renderResources(group, this.state.showEmail, startingIndex) : this.renderAsset(group, this.state.showEmail, startingIndex)}
                        </div>}
                    </div>
                </div>))
        });

        return html;
    }

    compare(a, b) {
        let fieldA = Number(a.fields.find(v => v.name == "sortorder").value);
        let fieldB = Number(b.fields.find(v => v.name == "sortorder").value);
        if (!fieldA && fieldB) return 1;
        if (fieldA < fieldB) {
            return -1;
        }
        if (fieldA > fieldB) {
            return 1;
        }
        return 0;
    }

    renderResources(asset, showEmail, startingIndex) {
        let { theme } = this.context;

        // TODO: how to check for resources without sortorder field? or will not having a sortorder field return 0...
        let sorted = asset.resources.sort(this.compare);

        return sorted.map((resource, i) => {
            let titleField = resource.fields.filter(f => f.name == "title")[0];
            return (
                <Link
                    key={`a${resource.ref}`}
                    className={`asset-box asset-box--${theme}`}
                    to={{
                        pathname: `${location.hash.slice(1, location.hash.indexOf('?'))}/gallery`,
                        search: `?type=${resource.resource_type}&item=${resource.rIndex === null ? startingIndex + i : resource.rIndex}`
                    }}
                >
                    <div className={`asset-img asset-img--${theme}`} style={{ backgroundImage: `url(${resource.url_scr})` }}>
                    </div>
                    <div className={`asset-file-extension asset-file-extension--${theme}`}>{resource.file_extension}</div>
                    <div className={`asset-img-text asset-img-text--${theme}`}>
                        <h6 className={`asset-text asset-text--${theme}`}>{titleField ? titleField.value : "No Title"}</h6>
                        <div className={`asset-mail-icon asset-mail-icon--${theme}`}>
                            {showEmail && (<Link to={`/request/${resource.ref}`}><img src="img/ico-mail.svg" /></Link>)}
                        </div>
                    </div>
                </Link>
            )
        })
    }

    renderAssetGroup(group, showEmail, startingIndex) {
        return group.map(asset => {
            return this.renderResources(asset, showEmail, startingIndex);
        });
    }

    renderAsset(resource, showEmail, i) {
        let { theme } = this.context;

        let titleField = resource.fields.filter(f => f.name == "title")[0];
        return (
            <Link
                key={`a${resource.ref}`}
                className={`asset-box asset-box--${theme}`}
                to={{
                    pathname: `${location.hash.slice(1, location.hash.indexOf('?'))}/gallery`,
                    search: `?type=${resource.resource_type}&item=${i}`
                }}
            >
                <div className={`asset-img asset-img--${theme}`} style={{ backgroundImage: `url(${resource.url_scr})` }}>
                </div>
                <div className={`asset-img-text asset-img-text--${theme}`}>
                    <h6 className={`asset-text asset-text--${theme}`}>{titleField ? titleField.value : "No Title"}</h6>
                    <div className="asset-mail-icon">
                        {showEmail && (<Link to={`/request/${resource.ref}`}><img src="img/ico-mail.svg" /></Link>)}
                    </div>
                </div>
            </Link>
        )
    }

    renderAssets(resources, showEmail, resourceTypes, resourceType, ref) {
        let { theme } = this.context;
        if (resourceTypes[1].ref === parseInt(resourceType) && this.state.assetSource === assetSources.PRODUCTS && theme === 'rbs') {
            // get distinct Photo Group field values for all resources
            let groupings = [];
            resources.map(r => {
                let tftField = r.fields.find(f => f.name == "photogroup");
                let title = '';
                if (tftField && tftField.value) {
                    title = tftField.value;
                }
                let grouping = groupings.filter(g => g.title == title)[0];
                if (grouping) { grouping.resources.push(r); }
                else { groupings.push({ title: title, resources: [r] }); }
            });
            return this.renderSquares(groupings, ref);
        } else {
            // render normally
            let groupings = [];
            resources.map(r => {
                let tftField = r.fields.find(f => f.name == "technicalfiletype") || r.fields.find(f => f.name == "photogroup");
                let title = '';
                if (tftField && tftField.value) {
                    title = tftField.value;
                }
                let grouping = groupings.filter(g => g.title == title)[0];
                if (grouping) { grouping.resources.push(r); }
                else { groupings.push({ title: title, resources: [r] }); }
            });
            return this.renderGroups(groupings, false);
        }
    }

    renderSquares(groupings, ref) {
        let { theme } = this.context;
        groupings.forEach(element => {
            var split = element.title.split(',');
            element.title = split[0];
            element.titleModified = split[1];
        });
        const grouped = groupBy(groupings, x => x.title);
        let squares = [];
        let html = [];
        let startingIndex = 0;

        grouped.forEach((group, groupIndex) => {
            let value = group.values;
            value.forEach((element, i) => {
                if (i === 0) {
                    let square = this.renderSquare(element.title, groupIndex, false, ref);
                    squares.push(square);
                }
                let group = this.renderGroup(element, true, groupIndex, startingIndex);
                //let group = this.renderGroup(element, true, element.title + element.titleModified);
                html.push(group);
                startingIndex += value[i].resources.length;
            });
        });

        return (
            <div>
                <div className="asset-photos-row">
                    <div className={`all-photos-button all-photos-button--${theme} ${this.state.showBackButton ? "" : "hidden"}`} onClick={() => this.onBackClick()}><img src="img/rbs-back-caret.svg" className={`all-photos-button-icon all-photos-button-icon--${theme}`} />{'All Photos'}</div>
                </div>
                <div className="row aseets-photos-row-inner">
                    {squares}
                    <div></div>
                </div>
                <div className="html-row">
                    {html}
                </div>
            </div>
        )
    }

    renderSquare(title, i, hidden, ref) {
        let { theme } = this.context;
        let { application, } = this.props;

        switch (application.currentClient) {
            case 'amf':
                return (
                    <div key={i} id={`square-${i}`} className={`product-row-item product-row-item--${theme} product-row-item-594 ${hidden ? 'hidden' : ''}`} style={{ backgroundImage: `url(./img/product-594-square.jpg)` }} onClick={() => this.renderImages(i)}>
                        <div className={`product-row-item-content product-row-item-content--${theme}`}>
                            <div className={`product-row-item-content-inner product-row-item-content-inner--${theme}`}>
                                <div className={`product-row-item-content-inner-margin product-row-item-content-inner-margin--${theme}`}>
                                    <h5 className={`product-row-text product-row-text--${theme}`}>{title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>)
            case 'rbs':
                return (
                    <div key={i} id={`square-${i}`} className={`product-row-item product-row-item--${theme} product-row-item-${ref} ${hidden ? 'hidden' : ''}`} onClick={() => this.renderImages(i)}>
                        <div className={`product-row-item-content product-row-item-content--${theme}`}>
                            <img className={`product-row-image product-row-image--${theme}`} src={`./img/product-${ref}-${i}-square--${theme}.png`} />
                            <div className={`product-row-item-content-inner product-row-item-content-inner--${theme}`}>
                                <div className={`product-row-item-content-inner-margin product-row-item-content-inner-margin--${theme}`}>
                                    <h5 className={`product-row-text product-row-text--${theme}`}>{title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    renderImages(i) {
        this.setState({ showBackButton: true });
        let squares = getElementsStartsWithId(`square-`);
        squares.forEach((element) => {
            element.classList.add('hidden');
        });

        let groups = getElementsStartsWithId(`groups-${i}`);
        groups.forEach((element) => {
            element.classList.remove('hidden');
        });
    }

    onBackClick() {
        this.setState({ showBackButton: false });
        let groups = getElementsStartsWithId(`groups-`);
        groups.forEach((element) => {
            element.classList.add('hidden');
        });

        let squares = getElementsStartsWithId(`square-`);
        squares.forEach((element) => {
            element.classList.remove('hidden');
        });
    }
    handleOnCollapsibleClick() {
        this.setState(preState => ({ ...preState, toggleCaretDownArrow: !preState.toggleCaretDownArrow }))
    }



    render() {
        let { showEmail, resourceType, resourceList, has3Dpdf, has2Dpdf, hasVideo, hasProducts, breadcrumbLinks, title, assetSource } = this.state;
        let { application, match } = this.props;
        let { theme } = this.context;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let resourcesToRender = resourceList.filter(r => r.resource_type == resourceType && r.file_extension != "md");

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // technical file
        if (resourceType == resourceType1 && assetSource === assetSources.UNITEQUIPMENT)
            resourcesToRender.sort(sortAssetsByMetadata(["photogroup"], [application.photogroups]));
        else
            resourcesToRender.sort(sortAssetsByMetadata(["technicalfiletype", "photogroup"], [application.technicalfiletypes, application.photogroups]));

        switch (application.currentClient) {
            case 'amf':
                return (<div>
                    <BreadcrumbsComponent links={breadcrumbLinks} />
                    <div className={`inner-width inner-width--${theme}`}>
                        <BackButtonComponent />
                        <div className="row">
                            <div className="col-12">
                                <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{title}</h1>
                            </div>
                        </div>
                        <nav>
                            <div className="nav text-center nav-tabs d-flex justify-content-md-start justify-content-sm-start justify-content-xs-start justify-content-lg-start  justify-content-xl-start" id="nav-tab" role="tablist">
                                <span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType1.ref ? "active" : ""}`} id="nav-home-tab" role="tab" aria-selected="true" onClick={() => this.setResourceType(resourceType1.ref)}> {this.renderImage(resourceType == resourceType1.ref)}{resourceType1.name}</span>
                                {has3Dpdf && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType6.ref ? "active" : ""}`} id="nav-profile-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType6.ref)}>{this.renderPdfImage(resourceType == resourceType6.ref)} {resourceType6.name}</span>)}
                                {has2Dpdf && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType2.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType2.ref)}>{this.renderBrochureImage(resourceType == resourceType2.ref)} {resourceType2.name}</span>)}
                                {hasVideo && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType3.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType3.ref)}>{this.renderVideoImage(resourceType == resourceType3.ref)} {resourceType3.name}</span>)}
                                {hasProducts && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType5.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType5.ref)}>{this.renderProductsImage(resourceType == resourceType5.ref)} {resourceType5.name}</span>)}
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {this.state.images.length === 0
                                ?
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {this.renderAssetContainer(resourceType, resourcesToRender, showEmail, application.resourceTypes, ref)}
                                </div>
                                :
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {this.renderGroup(this.state.images, false)}
                                </div>
                            }
                        </div>
                    </div>
                </div>)

            case 'rbs':
                if (isKiosk) {
                    resourcesToRender = resourcesToRender.filter(r => MetadataMapping.clients[1].kioskFileWhiteList.includes(r.file_extension))
                } else {
                    resourcesToRender = resourcesToRender.filter(r => !MetadataMapping.clients[1].nonKioskFileBlackList.includes(r.file_extension))
                }

                const renderNavTabs = () => {
                    return (
                        < nav >
                            {/* LHT Added className nav-tabs--rbs for responsiveness work*/}
                            < div className="nav text-center nav-tabs nav-tabs--rbs d-flex justify-content-md-start justify-content-sm-start justify-content-xs-start justify-content-lg-start  justify-content-xl-start" id="nav-tab" role="tablist" >
                                {(!application.isGuestMode) && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType1.ref ? "active" : ""}`} id="nav-home-tab" role="tab" aria-selected="true" onClick={() => this.setResourceType(resourceType1.ref)}> {this.renderRbsImage(resourceType == resourceType1.ref)}{resourceType1.name}</span>)}
                                {has3Dpdf && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType6.ref ? "active" : ""}`} id="nav-profile-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType6.ref)}>{this.renderTechnicalFileIcon(resourceType == resourceType6.ref)} {resourceType6.name}</span>)}
                                {has2Dpdf && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType2.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType2.ref)}>{this.renderRbsBrochureImage(resourceType == resourceType2.ref)} {resourceType2.name}</span>)}
                                {hasVideo && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType3.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType3.ref)}>{this.renderRbsVideoImage(resourceType == resourceType3.ref)} {resourceType3.name}</span>)}
                                {hasProducts && (<span className={`nav-item nav-item--${theme} nav-link nav-link--${theme} asset-drawer asset-drawer--${theme} ${resourceType == resourceType5.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => this.setResourceType(resourceType5.ref)}>{this.renderRbsProduct(resourceType == resourceType5.ref)} {resourceType5.name}</span>)}
                            </div >
                        </nav >
                    )
                }
                const setResourceTypeAndToggle = (ref) => {
                    this.handleOnCollapsibleClick();
                    this.setResourceType(ref);
                }
                const renderNavTabsMobile = () => {
                    return (
                        <div className={`nav-tabs assets-menu--${theme} ${this.state.toggleCaretDownArrow ? 'active' : ''}`}>
                            <div className={`btn-primary btn-primary--${theme} assets-button assets-button--${theme}`} onClick={this.handleOnCollapsibleClick}>
                                <span className='media-text'>MEDIA</span> <span className='media-img'><img className="fa asset-caret-right" src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='11px' viewBox='0 0 8 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3ERectangle Copy 6%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Unit-Equipment-System-Detail' transform='translate(-1293.000000, -221.000000)' fill='%23FFFFFF'%3E%3Cg id='Group' transform='translate(1222.000000, 217.000000)'%3E%3Cpolygon id='Rectangle-Copy-6' transform='translate(75.055556, 9.250000) rotate(90.000000) translate(-75.055556, -9.250000) ' points='80.1111111 12.5 73.2858246 12.5 70 12.5 75.0555556 6'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E" /></span>
                            </div>
                            <div className={`assets-menu-inner assets-menu-inner--${theme} `}>
                                <ul>
                                    {(!application.isGuestMode) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme} ${resourceType == resourceType1.ref ? "active" : ""}`} id="nav-home-tab" role="tab" aria-selected="true" onClick={() => setResourceTypeAndToggle(resourceType1.ref)}> {this.renderRbsImage(resourceType == resourceType1.ref)}{resourceType1.name}</li>)}
                                    {has3Dpdf && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme} ${resourceType == resourceType6.ref ? "active" : ""}`} id="nav-profile-tab" role="tab" aria-selected="false" onClick={() => setResourceTypeAndToggle(resourceType6.ref)}>{this.renderTechnicalFileIcon(resourceType == resourceType6.ref)} {resourceType6.name}</li>)}
                                    {has2Dpdf && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme} ${resourceType == resourceType2.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => setResourceTypeAndToggle(resourceType2.ref)}>{this.renderRbsBrochureImage(resourceType == resourceType2.ref)} {resourceType2.name}</li>)}
                                    {hasVideo && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme} ${resourceType == resourceType3.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => setResourceTypeAndToggle(resourceType3.ref)}>{this.renderRbsVideoImage(resourceType == resourceType3.ref)} {resourceType3.name}</li>)}
                                    {hasProducts && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme} ${resourceType == resourceType5.ref ? "active" : ""}`} id="nav-contact-tab" role="tab" aria-selected="false" onClick={() => setResourceTypeAndToggle(resourceType5.ref)}>{this.renderRbsProduct(resourceType == resourceType5.ref)} {resourceType5.name}</li>)}
                                </ul>
                            </div>
                        </div>
                    )
                }
                const { isMobile } = this.props.application;
                const { toggleCaretDownArrow } = this.state;
                const caretDown = <div className='color-white'>MEDIA<span><img className={`caret-down-rbs ${toggleCaretDownArrow ? "active" : ""}`} src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='11px' viewBox='0 0 8 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3ERectangle Copy 6%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Unit-Equipment-System-Detail' transform='translate(-1293.000000, -221.000000)' fill='%23FFFFFF'%3E%3Cg id='Group' transform='translate(1222.000000, 217.000000)'%3E%3Cpolygon id='Rectangle-Copy-6' transform='translate(75.055556, 9.250000) rotate(90.000000) translate(-75.055556, -9.250000) ' points='80.1111111 12.5 73.2858246 12.5 70 12.5 75.0555556 6'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E" /></span></div>
                return (<div>
                    <BreadcrumbsComponent links={breadcrumbLinks} />
                    <div className={`outer-width-rbs inner-width-rbs--${theme}`}>
                        <div className={`inner-width-rbs inner-width-rbs--${theme}`}>
                            <BackButtonComponent />
                            <div className="row">
                                <div className="col-12">
                                    <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{title}</h1>
                                </div>
                            </div>
                            {isMobile ?
                                // <Collapsible onOpen={this.handleOnCollapsibleClick} onClose={this.handleOnCollapsibleClick} trigger={caretDown} className={`nav text-center nav-tabs justify-content-center`} id={"asset-drawer-collapse-mobile"}>
                                //     {renderNavTabs()}
                                // </Collapsible>
                                renderNavTabsMobile()
                                : renderNavTabs()}

                            <div className="tab-content" id="nav-tabContent">
                                {this.state.images.length === 0
                                    ?
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        {this.renderAssetContainer(resourceType, resourcesToRender, showEmail, application.resourceTypes, ref)}
                                    </div>
                                    :
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        {this.renderGroup(this.state.images, false)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }
}

export default withRouter(connect(mapStateToProps)(AssetsComponent));
AssetsComponent.contextType = ThemeContext;