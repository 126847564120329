import { openDB, deleteDB } from 'idb';
import { IndexedDB } from '../config';
import { request } from 'http';

let dbName = IndexedDB.dbname;
let dbVersion = IndexedDB.dbversion;

export const Constants = {
    STORE_METADATA_SOLUTIONS: "metadata_productsystemsolutio",
    STORE_METADATA_BRANDS: "metadata_productbrand",
    STORE_METADATA_SUBSYSTEMS: "metadata_subsystem",
    STORE_METADATA_CATEGORIES: "metadata_category",
    STORE_METADATA_MODELS: "metadata_model",
    STORE_METADATA_UNITEQIPMENTS: "metadata_unitequipment",
    STORE_METADATA_TECHNICALFILETYPES: "metadata_technicalfiletype",
    STORE_METADATA_PHOTOGROUPS: "metadata_photogroup",
    STORE_COLLECTIONS: "collections",
    STORE_RESOURCES: "resources",
    STORE_USER: "user"
}

function getIndexDB() {
    return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
}

function testIndexDB(indexDb) {
    if(!indexDb) { return "Your browser does not support IndexedDB. This Application is not supported." }
}

function requestDatabase() {
    let testDB = testIndexDB(getIndexDB());
    if(testDB) { return { error: testDB } }
    return openDB(dbName, dbVersion, {
        upgrade(db, oldVersion, newVersion) {
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_SOLUTIONS)) {
                var objectStore_metadatasolutions = db.createObjectStore(Constants.STORE_METADATA_SOLUTIONS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_BRANDS)) {
                var objectStore_metadataproductbrands = db.createObjectStore(Constants.STORE_METADATA_BRANDS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_SUBSYSTEMS)) {
                var objectStore_metadatasubsystems = db.createObjectStore(Constants.STORE_METADATA_SUBSYSTEMS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_CATEGORIES)) {
                var objectStore_metadatacategories = db.createObjectStore(Constants.STORE_METADATA_CATEGORIES, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_MODELS)) {
                var objectStore_metadatamodels = db.createObjectStore(Constants.STORE_METADATA_MODELS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_UNITEQIPMENTS)) {
                var objectStore_metadataunitequipments = db.createObjectStore(Constants.STORE_METADATA_UNITEQIPMENTS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_TECHNICALFILETYPES)) {
                var objectStore_metadataunitequipments = db.createObjectStore(Constants.STORE_METADATA_TECHNICALFILETYPES, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_METADATA_PHOTOGROUPS)) {
                var objectStore_metadataunitequipments = db.createObjectStore(Constants.STORE_METADATA_PHOTOGROUPS, { keyPath: "ref" });
            }
            if (!db.objectStoreNames.contains(Constants.STORE_COLLECTIONS)) {
                var objectStore_collections = db.createObjectStore(Constants.STORE_COLLECTIONS, {keyPath: "ref"});
            }
            if (!db.objectStoreNames.contains(Constants.STORE_RESOURCES)) {
                var objectStore_resources = db.createObjectStore(Constants.STORE_RESOURCES, {keyPath: "ref"});
            }
            if (!db.objectStoreNames.contains(Constants.STORE_USER)) {
                var objectStore_users = db.createObjectStore(Constants.STORE_USER, {keyPath: "ref"});
            }
        }
    });
}

export function addToStore(storeName, item) {
    return requestDatabase().then(db => { 
        let tx = db.transaction(storeName, 'readwrite');
        let store = tx.objectStore(storeName);
        store.add(item);
        return tx.done;
    }).then(() => {
        return item;
    }).catch((ex) => {
        console.log(`IndexedDB could not add ${item.ref} to ${storeName}`);
        return item;
    });
}

export function updateInStore(storeName, item) {
    return requestDatabase().then(db => { 
        let tx = db.transaction(storeName, 'readwrite');
        let store = tx.objectStore(storeName);
        store.put(item);
        return tx.done;
    }).then(() => {
        return item;
    }).catch((ex) => {
        console.log(`IndexedDB could not update ${item.ref} to ${storeName}`);
        return item;
    });
}

export function getAllItemsFromStore(storeName) {
    return requestDatabase().then(db => {
        var tx = db.transaction(storeName, 'readonly');
        let store = tx.objectStore(storeName);
        return store.getAll();
    })
}

export function getItemFromStore(storeName, primaryKey) {
    return requestDatabase().then(db => {
        var tx = db.transaction(storeName, 'readonly');
        let store = tx.objectStore(storeName);
        return store.get(primaryKey);
    }).then((val) => {
        return val;
    })
}

export function deleteFromStore(storeName, primaryKey) {
    return requestDatabase().then(db => {
        let tx = db.transaction(storeName, 'readwrite');
        let store = tx.objectStore(storeName);
        store.delete(primaryKey);
        return tx.done;
    }).then(() => {
        return true;
    }).catch((ex) => {
        return false;
    })
}

export async function deleteDb() {
    await deleteDB(dbName);
}