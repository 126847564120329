import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

import BreadcrumbsComponent from '../ui/breadcrumbs';
import UnitEquipmentSmallComponent from '../ui/unitequipment-small-component';
import BackButtonComponent from '../ui/back-button-component';

function compareEquipment(a, b) {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
}

const mapStateToProps = state => ({
    application: state.Application
});

class SearchComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            solutions: [],
            subsystems: [],
            brands: [],
            equipment: [],
            selectedSolutions: [],
            selectedSubsystems: [],
            selectedBrands: [],
            itemsPerPage: 20,
            currentPage: 1,
        }

        this.setPage = this.setPage.bind(this);
        this.onSolutionsChange = this.onSolutionsChange.bind(this);
        this.onSubsystemsChange = this.onSubsystemsChange.bind(this);
        this.onBrandChange = this.onBrandChange.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
    }

    componentDidMount() {
        let { application } = this.props;
        let solutions = application.solutions.filter(s => s.parent == '');
        let subsystems = application.subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) === -1);
        let brands = application.brands;
        let equipment = application.resources.filter(r => 
                r.resource_type == "1"
                && r.fields
                && r.fields.filter(f => f.name == "primaryphoto" && f.value == "Enabled")[0])
            .map(coverPhotoResource => {
                if (!coverPhotoResource) { return null; }
                let solutionField = coverPhotoResource.fields.find(v => v.name == "productsystemsolutio");
                let brandField = coverPhotoResource.fields.find(v => v.name == "productbrand");
                let subsystemField = coverPhotoResource.fields.find(v => v.name == "sub-system");
                let unitequipmentField = coverPhotoResource.fields.find(v => v.name =="unitequipment");
                let solutionRef = solutionField.value ? solutions.find(s => s.name == solutionField.value.split(',')[0]) : null;
                let brandRef = brandField.value ? brands.find(b => b.name == brandField.value) : null;
                let subsystemRef = subsystemField.value ? subsystems.find(s => s.name == subsystemField.value.split(',')[0]) : null;
                let unitequipmentRef = unitequipmentField.value ? application.unitequipments.find(ue => ue.name == unitequipmentField.value) : null;
                return {
                    ref: unitequipmentRef ? unitequipmentRef.ref : null,
                    solutionRef: solutionRef ? solutionRef.ref : null,
                    brandRef: brandRef ? brandRef.ref : null,
                    subsystemRef: subsystemRef ? subsystemRef.ref : null,
                    name: unitequipmentField.value,
                    imageUrl: coverPhotoResource ? coverPhotoResource.url_scr : null
                }
        });
        equipment = equipment.filter(eq => eq != null && eq.ref != null && application.unitEquipmentRefsToHide.indexOf(eq.ref) == -1);
        equipment.sort(compareEquipment);
        console.log(equipment);

        this.setState({
            solutions: solutions,
            subsystems: subsystems,
            brands: brands,
            equipment: equipment
        });
    }

    doSearch() {

    }

    setPage(pageNum) {
        this.setState({ currentPage: pageNum });
        this.scrollTop();
    }

    onSolutionsChange(e) {
        let { selectedSolutions } = this.state;
        let indexOfSolution = selectedSolutions.indexOf(e.target.value);
        if (indexOfSolution == -1) {
            selectedSolutions.push(e.target.value);
        } else {
            selectedSolutions.splice(indexOfSolution, 1);
        }
        this.setState({ selectedSolutions: selectedSolutions.slice(), currentPage: 1 });
        this.scrollTop();
    }

    onSubsystemsChange(e) {
        let { selectedSubsystems } = this.state;
        let indexOfSubsystem = selectedSubsystems.indexOf(e.target.value);
        if (indexOfSubsystem == -1) {
            selectedSubsystems.push(e.target.value);
        } else {
            selectedSubsystems.splice(indexOfSubsystem, 1);
        }
        this.setState({ selectedSubsystems: selectedSubsystems.slice(), currentPage: 1 });
        this.scrollTop();
    }

    onBrandChange(e) {
        let { selectedBrands } = this.state;
        let indexOfBrand = selectedBrands.indexOf(e.target.value);
        if (indexOfBrand == -1) {
            selectedBrands.push(e.target.value);
        } else {
            selectedBrands.splice(indexOfBrand, 1);
        }
        this.setState({ selectedBrands: selectedBrands.slice(), currentPage: 1 });
        this.scrollTop();
    }

    onClearClick(e) {
        this.setState({
            selectedSolutions: [],
            selectedSubsystems: [],
            selectedBrands: [],
            currentPage: 1
        });
        document.querySelectorAll('input:checked').forEach((el) => {el.checked = false;})
        this.scrollTop();
    }

    scrollTop() {
        let el = document.querySelector('.backbutton');
        
        var rect = el.getBoundingClientRect();

        let isInView = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
        if(!isInView) el.scrollIntoView({behavior: "smooth"});
    }

    buildBreadcrumbLinks() {
        let links = [{
            title: 'Home',
            url: '/'
        }, {
            title: "Unit Equipment Search",
            url: "/search"
        }];
        return links;
    }

    filterEquipment(equipment, selectedSolutions, selectedSubsystems, selectedBrands) {
        if (selectedSolutions.length == 0 && selectedSubsystems.length == 0 && selectedBrands.length == 0) {
            return equipment;
        }
        return equipment.filter(eq => selectedSolutions.indexOf(eq.solutionRef) > -1
            || selectedSubsystems.indexOf(eq.subsystemRef) > -1
            || selectedBrands.indexOf(eq.brandRef) > -1);
    }

    renderCheckbox(label, name, value, onChange, theme) {
        return (<label key={`${name}${value}`} className={`checkbox-container checkbox-container--${theme}`}>{label}
            <input type="checkbox" name={name} value={value} onChange={onChange} />
            <span className={`checkmark checkmark--${theme}`}></span>
        </label>)
    }

    renderPagination(currentPage, maxPage) {
        let { theme } = this.context;
        if (currentPage == 1 && maxPage == 1) { return null; }
        return (<div className={`unit-equipment-next-button-row`}>
            {(currentPage > 2)
                && (<div className={`third-page-pagination-double-arrow third-page-pagination-double-arrow--${theme}`} onClick={() => this.setPage(1)}>
                    <div className={`second-page-pagination-button1-icon second-page-pagination-button1-icon--${theme}`}>
                       <img className="fa pagination-right-icon" src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='61px' height='61px' viewBox='0 0 61 61' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3EGroup%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='noun_Double-Left-Caret_196763' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group'%3E%3Cpath d='M26.131,1.714 L1.802,26.043 C1.775,26.069 1.737,26.077 1.711,26.104 C0.565,27.25 -0.004,28.763 -2.09787218e-05,30.274 C-0.004,31.785 0.564,33.297 1.711,34.444 C1.738,34.471 1.775,34.478 1.802,34.505 L26.131,58.834 C28.416,61.119 32.155,61.119 34.439,58.834 C36.724,56.549 36.724,52.81 34.439,50.526 L14.189,30.273 L34.44,10.022 C36.725,7.737 36.725,3.998 34.44,1.714 C32.155,-0.571 28.416,-0.571 26.131,1.714 Z' id='Path'%3E%3C/path%3E%3Cpath d='M50.056,1.714 L25.727,26.043 C25.7,26.069 25.662,26.077 25.636,26.104 C24.49,27.25 23.921,28.763 23.924979,30.274 C23.921,31.785 24.489,33.297 25.636,34.444 C25.663,34.471 25.7,34.478 25.727,34.505 L50.056,58.834 C52.341,61.119 56.08,61.119 58.364,58.834 C60.649,56.549 60.649,52.81 58.364,50.526 L38.113,30.273 L58.364,10.022 C60.649,7.737 60.649,3.998 58.364,1.714 C56.079,-0.571 52.34,-0.571 50.056,1.714 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"/>
                    </div>
                </div>)
            }
            {(currentPage > 1)
                && (<div className={`second-page-pagination-button1 second-page-pagination-button1--${theme}`} onClick={() => this.setPage(currentPage - 1)}>
                    <div className={`second-page-pagination-button1-icon second-page-pagination-button1-icon--${theme}`}>
                    <img className="fa pagination-right-icon" src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='37px' height='61px' viewBox='0 0 37 61' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3Enoun_Left Caret_196760%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='noun_Left-Caret_196760' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M26.978,1.714 L2.649,26.043 C2.622,26.069 2.584,26.077 2.558,26.104 C1.412,27.25 0.843,28.763 0.846979021,30.274 C0.843,31.785 1.411,33.297 2.558,34.444 C2.585,34.471 2.622,34.478 2.649,34.505 L26.978,58.834 C29.263,61.119 33.002,61.119 35.286,58.834 C37.57,56.549 37.571,52.81 35.286,50.526 L15.036,30.273 L35.287,10.022 C37.572,7.737 37.572,3.998 35.287,1.714 C33.002,-0.57 29.263,-0.571 26.978,1.714 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E"/>
                    </div>
                </div>)
            }
            <div className={`unit-equipment-next-button-row2 unit-equipment-next-button-row2--${theme}`}>
                <h6 className={`unit-equipment-next-button-text unit-equipment-next-button-text--${theme}`}>{currentPage == 1 ? "Next" : `Page ${currentPage}`}</h6>
            </div>
            {(currentPage >= maxPage)
                ? (<div className={`last-page-pagination-blank last-page-pagination-blank--${theme}`}></div>)
                : (<div className={`unit-equipment-next-button-button unit-equipment-next-button-button--${theme}`} onClick={() => this.setPage(currentPage + 1)}>
                    <div className={`unit-equipment-next-button-icon unit-equipment-next-button-icon--${theme}`}>
                        <img className="fa pagination-right-icon" src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='37px' height='61px' viewBox='0 0 37 61' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='noun_Right-Caret_196764' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M10.869,58.832 L35.198,34.503 C35.225,34.477 35.263,34.469 35.289,34.442 C36.435,33.296 37.004,31.783 37.000021,30.272 C37.004,28.761 36.436,27.249 35.289,26.102 C35.262,26.075 35.225,26.068 35.198,26.041 L10.869,1.714 C8.584,-0.571 4.845,-0.571 2.561,1.714 C0.277,3.999 0.276,7.738 2.561,10.022 L22.812,30.273 L2.56,50.524 C0.275,52.809 0.275,56.548 2.56,58.832 C4.845,61.117 8.584,61.117 10.869,58.832 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E"/>
                    </div>
                </div>)
            }
        </div>);
    }

    render() {
        let { currentPage, itemsPerPage, solutions, subsystems, brands, selectedSolutions, selectedSubsystems, selectedBrands } = this.state;
        let equipment = this.filterEquipment(this.state.equipment, selectedSolutions, selectedSubsystems, selectedBrands);
        let maxPage = (equipment.length <= itemsPerPage) ? 1 : Math.ceil(equipment.length / itemsPerPage);
        let firstIndex = currentPage > 1 ? ((currentPage - 1) * itemsPerPage) : 0;
        let equipmentToRender = equipment.slice(firstIndex, (firstIndex + itemsPerPage));
        let hasFilters = selectedSolutions.length > 0 || selectedSubsystems.length > 0 || selectedBrands.length > 0;
        let { theme } = this.context;
        return (
            <div>
                <BreadcrumbsComponent links={this.buildBreadcrumbLinks()} />

                <div className="inner-width">
                    <div className={`backbtn backbtn--${theme}`} >
                    <BackButtonComponent />
                    </div>
                    <div className="row">
                        <div className="unit-search-page-title-container">
                            <div className={`unit-search-page-title-row unit-search-page-title-row--${theme}`}>
                                <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}> Unit Equipment Search</h1> <p className="unit-search-results">({equipment.length} results)</p>
                                {hasFilters && (<button className={`btn btn-primary btn-primary--${theme}`} type="button" onClick={this.onClearClick}>Clear Filters</button>)}
                            </div>
                        </div>
                    </div>
                    <div className={`unit-equipment-search-row unit-equipment-search-row--${theme}`}>
                        <div className={`unit-search-column unit-search-column--${theme}`}>
                            <div className="unit-search-column-limiter">
                                <div className={`d-flex align-items-start justify-content-start unit-search-title unit-search-title--${theme}`}>
                                    <h3>Products</h3>
                                </div>
                                {solutions.map(s => {
                                    return (this.renderCheckbox(s.name, "solutions", s.ref, this.onSolutionsChange, theme))
                                })}
                                <div className={`d-flex align-items-start justify-content-start unit-search-title unit-search-title--${theme}`}>
                                    <h3>Process</h3>
                                </div>
                                {subsystems.map(s => {
                                    return (this.renderCheckbox(s.name, "subsystems", s.ref, this.onSubsystemsChange, theme))
                                })}
                                <div className={`d-flex align-items-start justify-content-start unit-search-title unit-search-title--${theme}`}>
                                    <h3>Sub-Brand</h3>
                                </div>
                                {brands.map(s => {
                                    return (this.renderCheckbox(s.name, "brand", s.ref, this.onBrandChange, theme))
                                })}
                            </div>
                        </div>
                        <div className={`unit-equipment-column unit-equipment-column--${theme}`}>
                        <div className={`unit-equipment-row1-container unit-equipment-row1-container--${theme}`}>
                            <div className={`unit-equipment-row1 unit-equipment-row1--${theme}`}>
                                {equipmentToRender.map((ue, i) => { return (<UnitEquipmentSmallComponent key={`ue${ue.ref}${i}`} id={ue.ref} name={ue.name} imageUrl={ue.imageUrl} />) })}
                                {this.renderPagination(currentPage, maxPage)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(SearchComponent));
SearchComponent.contextType = ThemeContext;