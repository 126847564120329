import React, { PureComponent } from 'react';
import ErrorUI from '../ui/error/component';

class ErrorBoundary extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });

        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorUI />;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;