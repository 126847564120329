import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ResourceSpaceEmailFrom, ResourceSpaceApiKey, ResourceSpaceApiUser } from '../../config';
import BackButtonComponent from '../ui/back-button-component';
import { ThemeContext } from '../../theme-context';

import { API as api_config } from '../../config';
var api = require('../../api_module/api')(api_config.user, api_config.key, api_config.url);

const mapStateToProps = state => ({
    application: state.Application
});

const initialValues = {
    title: '',
    email: '',
    ref: 0
};

class EmailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeElementName: '',
            submitMessage: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    shouldComponentUpdate() {
        //return false;
        return true;
    }

    onSubmit = async (values) => {      
        try {
            api.email.sendEmailWithAttachment(values.email, ResourceSpaceEmailFrom, values.ref)
                .then(values => {
                    console.log(values);
                }).catch(err => {
                    console.log(err);
                })

            this.setState({ formSubmitted: true, submitMessage: "Thank you for your request!" })
        } catch (error) {
            console.log('There has been a problem with your fetch operation: ', error.message);
            this.setState({ formSubmitted: true, submitMessage: "There was an error with your request. Please try again later." })
            return false;
        }
  
        return true;
    }

    goBack(e) {
        e.preventDefault();

        let { history } = this.props;
        history.goBack();
    }

    renderForm(initialValues, onSubmit) {
        let { theme } = this.context;
        return (<Formik
            ref={this.form}
            initialValues={initialValues}
            onSubmit={onSubmit}>
            <Form className="contact-form">
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="email">Email Address</label>
                    <Field
                        name="email"
                        type="text"
                        className="form-control"
                    />
                </div>
                <button type="submit" className={`btn btn-primary btn-primary--${theme} float-right`}>Send Request</button>
            </Form>
        </Formik>);
    }

    renderMessage(goBack) {
        let { theme } = this.context;
        return (<div>
            <p className={`contact-description contact-description--${theme}`}>{this.state.submitMessage}</p>
            <button className={`btn btn-primary btn-primary--${theme} float-right`} onClick={goBack}>Back to Assets</button>
        </div>);
    }
    renderIcon(){
        let { formSubmitted} = this.state;
        let { theme } = this.context;

        if (formSubmitted == true) {
            return(
                <div>
                <h2 className={`contact-title contact-title--${theme}`}><img src="img/rbs-asset-success.svg" /> Asset Requested</h2>
                </div>
            ) 
            }else{
                return(
                    <div>
                    <h2 className={`contact-title contact-title--${theme}`}><img src="img/rbs-request-asset-ico.svg" /> Request Asset</h2>
                    </div>
            )
        }
    }

    renderEmail(){
        let { application, match } = this.props;
        let { formSubmitted } = this.state;

        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let resource = application.resources.find(r => r.ref == ref);
        let title = resource.fields.find(f => f.name == "title").value;
        let { theme } = this.context;

        initialValues.title = title;
        initialValues.ref = match.params.ref;

        switch (application.currentClient) {
            case 'amf':
                return ( <div>
                    <div className={`contact-backbutton contact-backbutton--${theme}`}>
                    <BackButtonComponent />
                    </div>
                    <div className="inner-width-override">          
                        <div className={`contact-row-override contact-row-override--${theme}`}>
                            <h2 className={`contact-title contact-title--${theme}`}><img src="img/ico-mail-request.svg" /> Request Asset</h2>
                            <p className={`contact-description contact-description--${theme}`}>Please provide your email address to request the resource <span className="italicize"> "{title}"</span>.</p>
                            <p className={`contact-description contact-description--${theme}`}>You will receive the asset from {ResourceSpaceEmailFrom} shortly.</p>
                            {formSubmitted
                                ? this.renderMessage(this.goBack)
                                : this.renderForm(initialValues, this.onSubmit)
                            }
                        </div>
                    </div>
                </div>)

            case 'rbs':
                return ( <div>
                    <div className={`contact-backbutton contact-backbutton--${theme}`}>
                    <BackButtonComponent />
                    </div>
                    <div className="inner-width-override">          
                        <div className={`contact-row-override contact-row-override--${theme}`}>
                            {this.renderIcon()}
                            <p className={`contact-description contact-description--${theme}`}>Please provide your email address to request the resource <span className="italicize"> "{title}"</span>.</p>
                            <p className={`contact-description contact-description--${theme}`}>You will receive the asset from {ResourceSpaceEmailFrom} shortly.</p>
                            {formSubmitted
                                ? this.renderMessage(this.goBack)
                                : this.renderForm(initialValues, this.onSubmit)
                            }
                        </div>
                    </div>
                </div>)
    }
}

    render() {

        return (
            this.renderEmail()
        );
    }
}

export default withRouter(connect(mapStateToProps)(EmailComponent));
EmailComponent.contextType = ThemeContext;