import ApiResource from '../apiResource';
import ApiMethod from '../apiMethod';

module.exports = class Account extends ApiResource {
    constructor(api) {
        super(api);

        this.getProductSystemSolutions = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getProductBrands = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getSubSystemProcesses = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getCategories = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getModels = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getUnitEquipments = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })

        this.getByFieldId = ApiMethod({
            method: 'GET',
            function: 'get_field_options',
            urlParams: ['param1'],
            params: {
                param1: '',
                param2: true
            }
        })
    }
}