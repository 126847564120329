import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { loadAllMetadata, loadCollections, loadAllResources, loadAssets, completeLoading, checkAllMetadata, checkAllCollections, checkAllResources, checkAssets, loadEverything } from './actions';
import LoaderUI from '../ui/loader/component';
import * as app_constants from '../application/constants';
import { MetadataMapping } from '../../config';

const mapStateToProps = state => ({
    isLoading: state.Application.isLoading,
    forceUpdate: state.Application.forceUpdate,
    metaDataToLoad: state.Application.metaDataToLoad,
    collectionsToLoad: state.Application.collectionsToLoad,
    resourcesToLoad: state.Application.resourcesToLoad,
    assetChunksToLoad: state.Application.assetChunksToLoad,
    totalCollections: state.Application.totalCollections,
    totalResources: state.Application.totalResources,
    currentBatch: state.Application.currentBatch,
    assetBatchCount: state.Application.assetBatchCount,
    counter: state.Application.counter,
    validation: state.Application.validation,
    metadataMapping: state.Application.metadataMapping,
    collectionsComplete: state.Application.collectionsComplete,
    resourcesComplete: state.Application.resourcesComplete,
});

const electron = window.electron;

class Loader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            startedValidation: false,
            sync: false,
            startedMetadataLoad: false,
            startedCollectionLoad: false,
            startedResourceLoad: false,
            startedAssetsLoad: false,
            willLoadAssets: props.assetChunksToLoad !== undefined,
            startedAssetLoad: false,
            initialAssetCount: 0,
            step: 0,
            loadStarted: false
        }
    }

    async componentWillMount() {
        let { dispatch, validation } = this.props;

        if (this.props.forceUpdate) {
            //console.log("sync assets clicked");
            this.setState({
                startedValidation: true,
                sync: false,
                startedMetadataLoad: false,
                startedCollectionLoad: false,
                startedResourceLoad: false,
                startedAssetsLoad: false,
                willLoadAssets: this.props.assetChunksToLoad !== undefined,
                startedAssetLoad: false,
                initialAssetCount: 0,
                step: 0
            }, () => {
                this.setState({sync: true, step: 1});
            });
        } else {
            //console.log("initial load");
            this.setState({startedValidation: true}, async () => {
                // set "sync" variable to true to test force sync on load
                const sync = await this.checkIndexedDb(validation);
                if (sync) {
                    //console.log("need to sync");
                    this.setState({sync: true, step: -999});
                } else {
                    //console.log("no sync needed");
                    this.setState({sync: false, step: 1})
                }
            })
        }
    }

    checkIndexedDb = async (validation) => {
        let assets = false;
        const metadata = await checkAllMetadata(validation);
        const collections = await checkAllCollections();
        const resources = await checkAllResources();

        if (electron) {
            assets = await checkAssets();
        }

        return metadata || collections || resources || assets ? true : false;
    }

    onSyncClick = () => {
        this.setState({sync: true, step: 1});
    };

    async componentDidUpdate(prevProps) {
        let { dispatch, metadataMapping, collectionsComplete, resourcesComplete } = this.props;

        if (!this.state.sync && !this.state.loadStarted) {
            this.setState({loadStarted: true}, () => {
                dispatch(loadEverything(electron));
            })
        } else if (!this.state.sync && this.state.loadStarted && collectionsComplete && resourcesComplete) {
            dispatch(completeLoading());
        }

        if (this.state.sync && this.state.step !== -999) {
            let that = this;

            if (!this.state.startedMetadataLoad) {
                // console.log("1. load metadata");
                this.setState({startedMetadataLoad: true, startedValidation: true})
                setTimeout(() => {
                    if (metadataMapping === undefined) {
                        let localMetadataMapping = {};
                        if (process.vendor === 'amf') {
                            console.log('amf');
                            localMetadataMapping = MetadataMapping.clients.filter(t => t.name == "amf")[0];
                        } else if (process.vendor === 'rbs') {
                            console.log('rbs');
                            localMetadataMapping = MetadataMapping.clients.filter(t => t.name == "rbs")[0];
                        }

                        const productsystemsolution = localMetadataMapping.filter(m => m.shorthand_name == "productsystemsolutio")[0];
                        const productbrand = localMetadataMapping.filter(m => m.shorthand_name == "productbrand")[0];
                        const subsystem = localMetadataMapping.filter(m => m.shorthand_name == "sub-system")[0];
                        const category = localMetadataMapping.filter(m => m.shorthand_name == "category")[0];
                        const model = localMetadataMapping.filter(m => m.shorthand_name == "model")[0];
                        const unitequipment = localMetadataMapping.filter(m => m.shorthand_name == "unitequipment")[0];
                        const technicalfiletype = localMetadataMapping.find(m => m.shorthand_name === "technicalfiletype");
                        dispatch(loadAllMetadata(true, [productsystemsolution, productbrand, subsystem, category, model, unitequipment, technicalfiletype]));
                        that.setState({ step: 1 });
                    } else {
                        const productsystemsolution = metadataMapping.filter(m => m.shorthand_name == "productsystemsolutio")[0];
                        const productbrand = metadataMapping.filter(m => m.shorthand_name == "productbrand")[0];
                        const subsystem = metadataMapping.filter(m => m.shorthand_name == "sub-system")[0];
                        const category = metadataMapping.filter(m => m.shorthand_name == "category")[0];
                        const model = metadataMapping.filter(m => m.shorthand_name == "model")[0];
                        const unitequipment = metadataMapping.filter(m => m.shorthand_name == "unitequipment")[0];
                        const technicalfiletype = metadataMapping.find(m => m.shorthand_name === "technicalfiletype");
                        const photogroup = metadataMapping.find(m => m.shorthand_name === "photogroup");
                        dispatch(loadAllMetadata(true, [productsystemsolution, productbrand, subsystem, category, model, unitequipment, technicalfiletype, photogroup]));
                        that.setState({ step: 1 });
                    }
                }, 500);
            }
            
            if (this.props.metaDataToLoad <= 0 && prevProps.metaDataToLoad && !this.state.startedCollectionLoad) {
                // console.log("2. load collections");
                this.setState({startedCollectionLoad: true})
                dispatch({
                    type: app_constants.RESET_COUNTER
                });
                setTimeout(() => {
                    dispatch(loadCollections(true));
                    that.setState({ step: this.state.step + 1 });
                }, 500);
            }

            if (this.props.collectionsToLoad <= 0 && prevProps.collectionsToLoad && !this.state.startedResourceLoad) {
                // console.log("3. load resources");
                this.setState({startedResourceLoad: true})
                dispatch({
                    type: app_constants.RESET_COUNTER
                });
                setTimeout(() => {
                    dispatch(loadAllResources(true));
                    this.setState({ step: this.state.step + 1 });
                }, 500);
            }

            if (this.props.resourcesToLoad <= 0 && prevProps.resourcesToLoad > 0 && this.props.isLoading) {
                // console.log("4. load assets");
                if (!this.state.willLoadAssets) {
                    setTimeout(() => {
                        dispatch(completeLoading());
                    }, 500);
                } else if (!this.props.startedAssetLoad) {
                    dispatch({
                        type: app_constants.RESET_COUNTER
                    });
                    setTimeout(() => {
                        this.setState({ startedAssetLoad: true, step: this.state.step + 1 });
                        dispatch(loadAssets(true));
                    }, 500);
                }
            }

            if(this.state.willLoadAssets) {
                if (this.props.assetChunksToLoad.length > 0 && !this.state.initialAssetCount) {
                    this.setState({ initialAssetCount: this.props.assetChunksToLoad.length  });
                }
                if (this.props.assetChunksToLoad.length === 0 && prevProps.assetChunksToLoad.length > 0 && this.props.isLoading) {
                    setTimeout(() => {
                        dispatch(completeLoading());
                    }, 1000);
                }
            }
        }
    }

    render() {
        let { isLoading, totalCollections, totalResources, counter, currentBatch, assetBatchCount } = this.props;
        let { startedValidation, startedCollectionLoad, startedResourceLoad, willLoadAssets, startedAssetLoad, step, loadStarted } = this.state;

        let loadingPercentage = 0;
        let hidePercentageBar = false;

        let loadingText = "Loading...";

        if (loadStarted) {
            return (
                <LoaderUI hidePercentageBar={hidePercentageBar} />
            );
        }

        if (!startedValidation) loadingText = "Validating collections..."
        else if (!startedCollectionLoad) loadingText = "Loading Metadata...";
        else if (!startedResourceLoad) loadingText = `Loading Collection ${counter} of ${totalCollections}`;
        else if ((!willLoadAssets && isLoading) || (willLoadAssets && !startedAssetLoad)) loadingText = `Loading Resource ${counter} of ${totalResources}`;
        else if (willLoadAssets && isLoading) {
            loadingPercentage = Math.floor((currentBatch / assetBatchCount) * 100);
            if(isNaN(loadingPercentage)) { loadingPercentage = 0; }
            loadingText = `Loading Assets in Batch ${currentBatch} of ${assetBatchCount}`;
        }
        else loadingText = "Loading Complete!";

        if (startedCollectionLoad) {
            loadingPercentage = Math.round((counter / totalCollections) * 100);
        }
        if (startedResourceLoad) {
            loadingPercentage = Math.round((counter / totalResources) * 100);
        }

        return (
            <LoaderUI loadingPercentage={loadingPercentage} loadingText={loadingText} step={step} electron={electron} onSyncClick={this.onSyncClick} hidePercentageBar={hidePercentageBar} />
        );
    }
}


export default connect(mapStateToProps)(Loader);