import React, { PureComponent } from 'react';
import { ThemeContext } from '../../theme-context';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    application: state.Application
});

class SquareOnClickComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderDownload(){
        let {title, onClick, classRef, application} = this.props;
        let { theme } = this.context;

        switch (application.currentClient) {
            case 'amf':
                return (<div className={`product-row-item product-row-item--${theme} product-row-item-${classRef}`} style={{backgroundImage:`url(./img/product-${classRef}-square.jpg)`}}>
                <div onClick={onClick} className={`product-row-item-content product-row-item-content--${theme}`}>
                    <div className={`product-row-item-content-inner product-row-item-content-inner--${theme}`}>
                        <div className={`product-row-item-content-inner-margin product-row-item-content-inner-margin--${theme}`}>
                        <h5 className={`product-row-text product-row-text--${theme}`}>{title}</h5>
                        </div>
                    </div>
                </div>
            </div>)
            case 'rbs':
                return (<div className={`download-product-row-item download-product-row-item--${theme} product-row-item-${classRef}`} style={{backgroundImage:`url(./img/product-${classRef}-square.jpg)`}}>
                <div onClick={onClick} className={`download-product-row-item-content download-product-row-item-content--${theme}`}>
                    <div className={`download-product-row-item-content-inner download-product-row-item-content-inner--${theme}`}>
                        <div className={`download-product-row-item-content-inner-margin download-product-row-item-content-inner-margin--${theme}`}>
                        <h5 className={`download-product-row-text download-product-row-text--${theme}`}>{title}</h5>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

    render() {
        return(
            this.renderDownload()
        );
    }
}

export default withRouter(connect(mapStateToProps)(SquareOnClickComponent));
SquareOnClickComponent.contextType = ThemeContext;