import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

class SecondaryLinkComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        let {title, uri} = this.props;
        let { theme } = this.context;
        let caretColor = 'FFFFFF';
        if(theme === 'amf') { caretColor = 'E31B23'; }
        return(
                
                <div className={`buttons-below-images-box buttons-below-images-box--${theme}`}>
                    <Link to={uri}>
                        <h5 className={`text-center buttons-below-images-line buttons-below-images-line--${theme}`}>{title}<img className="fa secondary-link-caret-right" src={`data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='11px' viewBox='0 0 8 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3ERectangle Copy 6%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Unit-Equipment-System-Detail' transform='translate(-1293.000000, -221.000000)' fill='%23${caretColor}'%3E%3Cg id='Group' transform='translate(1222.000000, 217.000000)'%3E%3Cpolygon id='Rectangle-Copy-6' transform='translate(75.055556, 9.250000) rotate(90.000000) translate(-75.055556, -9.250000) ' points='80.1111111 12.5 73.2858246 12.5 70 12.5 75.0555556 6'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E`}/></h5>
                    </Link>
                </div>
            );
    }
}

export default withRouter(SecondaryLinkComponent);
SecondaryLinkComponent.contextType = ThemeContext;