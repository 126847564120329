import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../theme-context';

class BlacklistErrorUI extends PureComponent {
    constructor(props) {
        super(props);

        this.goBack = e => {
            e.preventDefault();

            let {
                setBlacklistFalse
            } = this.props;

            setBlacklistFalse();
        };
    }

    render() {
        return (
            <div className="error alert alert-danger">
                <img className="fas warning-icon" src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='90px' height='82px' viewBox='0 0 90 82' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3Enoun_Warning_1145829%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='noun_Warning_1145829' fill='%23721C24' fill-rule='nonzero'%3E%3Cpath d='M88.61,65.95 L71.25,35.87 L53.88,5.8 C52.0458155,2.63083052 48.6616755,0.679377394 45,0.679377394 C41.3383245,0.679377394 37.9541845,2.63083052 36.12,5.8 L18.75,35.87 L1.39,65.95 C-0.437176889,69.1229971 -0.434947209,73.0292499 1.39585077,76.200159 C3.22664874,79.3710681 6.60851535,81.3260091 10.27,81.33 L79.73,81.33 C83.3914847,81.3260091 86.7733513,79.3710681 88.6041492,76.200159 C90.4349472,73.0292499 90.4371769,69.1229971 88.61,65.95 Z M48.26,67 L41.74,67 L41.74,60.48 L48.26,60.48 L48.26,67 Z M50.66,33.93 L48.22,57.06 L41.78,57.06 L39.34,33.93 L39.34,29.07 L50.66,29.07 L50.66,33.93 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E" />
                <div>
                    <p>Sorry, this email address is not valid to view our content.</p>
                    <p>Please <Link to="/" onClick={this.goBack}>go back</Link> to the previous screen and try another email or email us at info@readingbakery.com to request access directly.</p>
                </div>
            </div>
        );
    }
}

export default withRouter(BlacklistErrorUI);
BlacklistErrorUI.contextType = ThemeContext;