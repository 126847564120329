export const TEMP = 'TEMP';

export const ELECTRON_TEMP = 'ELECTRON_TEMP';

export const SET_METADATA_SOLUTIONS = "SET_METADATA_PRODUCTSYSTEMSOLUTIO";
export const SET_METADATA_BRANDS = "SET_METADATA_PRODUCTBRAND";
export const SET_METADATA_SUBSYSTEMS = "SET_METADATA_SUBSYSTEM";
export const SET_METADATA_CATEGORIES = "SET_METADATA_CATEGORY";
export const SET_METADATA_MODELS = "SET_METADATA_MODEL";
export const SET_METADATA_UNITEQIPMENTS = "SET_METADATA_UNITEQUIPMENT";
export const SET_METADATA_TECHNICALFILETYPES = "SET_METADATA_TECHNICALFILETYPE";
export const SET_METADATA_PHOTOGROUPS = "SET_METADATA_PHOTOGROUP";
export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const RESET_COUNTER = "RESET_COUNTER";
export const SET_RESOURCES = "SET_RESOURCES";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const CLEAN_RESOURCE = "CLEAN_RESOURCE";
export const LOAD_ASSETS = "LOAD_ASSETS";
export const SET_ASSETS = "SET_ASSETS";
export const SET_ASSET_BATCH_COUNT = "SET_ASSET_BATCH_COUNT";
export const SET_ASSET_CURRENT_BATCH = "SET_ASSET_CURRENT_BATCH";
export const UPDATE_ASSETS = "UPDATE_ASSETS";
export const COMPLETE_LOADING = "COMPLETE_LOADING";
export const BEGIN_SYNC = "BEGIN_SYNC";
export const SET_USER_LOGGEDIN = "SET_USER_LOGGEDIN";
export const SET_USER_GUESTMODE = "SET_USER_GUESTMODE";
export const COLLECTIONS_COMPLETE = "COLLECTIONS_COMPLETE";
export const RESOURCES_COMPLETE = "RESOURCES_COMPLETE";
export const CHECK_TEMP_FOLDER = "CHECK_TEMP_FOLDER";
export const SET_METADATA_MAPPING = "SET_METADATA_MAPPING";
export const SET_IS_MOBILE = "SET_IS_MOBILE";

export const FETCH_MARKDOWN_FILE = "FETCH_MARKDOWN_FILE";
export const SAVE_ASSET_REQUEST = "SAVE_ASSET_REQUEST";
export const DOWNLOAD_ASSET = "DOWNLOAD_ASSET";
export const REQUEST_ASSET_EMAIL = "REQUEST_ASSET_EMAIL";
