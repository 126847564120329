export function groupBy(list, keyGetter) {
	const map = [];
	list.forEach(item => {
		const key = keyGetter(item);
		const collection = map.find(m => m.key === key);
		if (!collection) {
			map.push({ key: key, values: [item]});
		} else {
			collection.values.push(item);
		}
	});

	return map;
};
