import React, { Component, Fragment } from 'react';
import { ThemeContext } from './theme-context';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import LoaderComponent from './components/loader/component';
import LoginComponent from './components/loader/login-component';
import Application from './components/application/component';
import Solutions from './components/solutions/component'
import Product from './components/product/component';
import ProductDetail from './components/product-detail/component';
import Footer from './components/ui/footer/component';
import Header from './components/ui/header/component';
import SubsystemComponent from './components/subsystem/component';
import UnitEquipmentComponent from './components/unitequipment/component';
import SearchComponent from './components/search/component';
import AssetComponent from './components/assets/component';
import AssetGallery from './components/asset-gallery/component';
import RequestsComponent from './components/requests/component';
import ProcessComponent from './components/process/component';
import ErrorBoundary from './components/error-boundary/component';
import ErrorUI from './components/ui/error/component';
import DownloadComponent from './components/download/component';
import EmailComponent from './components/email/component';
import IdleTimer from 'react-idle-timer';

import { inactivePeriod } from './config';

const noHeaderFooterRoutes = ['request', 'gallery']

const mapStateToProps = state => ({
  isLoggedIn: state.Application.isLoggedIn,
  isLoading: state.Application.isLoading,
  isGuestMode: state.Application.isGuestMode
});


const isKiosk = process.kiosk;
class RoutesComponent extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
  }

  componentDidMount() {
    const isMobile = window.innerWidth < 768;
    const action = {
      type: "SET_IS_MOBILE",
      data: { isMobile: isMobile }
    }
    this.props.dispatch(action);
  }

  onIdle () {
    console.log('on idle');
    if (this.props.location.pathname !== "/") {
      this.props.history.push('/');
    }
  };

  render() {
    let { isLoading, isLoggedIn, isGuestMode } = this.props;
    let { theme } = this.context;
    //If URL contains "mystring"
    if (isGuestMode && window.location.href.indexOf("type=1") > -1) {
      console.log('Error: Guest can not view non-primary photo');
      return <ErrorUI />
    }
    if (!isLoggedIn) return (<LoginComponent />)
    if (isLoading) return (<LoaderComponent />)
    let showHeaderFooter = true;
    noHeaderFooterRoutes.map(r => {
      if (window.location.hash.indexOf(r) > -1) showHeaderFooter = false;
    });

    return (
      <div className={`app-container--${theme}`} >
        {showHeaderFooter && (<Header />)}
        {isKiosk && <IdleTimer
        element={document}
        ref={ref => { this.idleTimer = ref }}
        timeout={inactivePeriod}
        onIdle={this.onIdle}
        />}
        <Fragment>
          <Switch>
            <Route exact path="/" render={props =>
              <ErrorBoundary key='1'>
                <Application {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/subsystems/:ref" render={props =>
              <ErrorBoundary key='2'>
                <SubsystemComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/subsystems/process/:ref" render={props =>
              <ErrorBoundary key='3'>
                <ProcessComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/solutions/:ref" render={props =>
              <ErrorBoundary key='4'>
                <Solutions {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref" render={props =>
              <ErrorBoundary key='5'>
                <Product {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref/assets" render={props =>
              <ErrorBoundary key='6'>
                <AssetComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref/assets/gallery" render={props =>
              <ErrorBoundary key='6'>
                <AssetGallery {...props} />
              </ErrorBoundary>
            } />

            <Route path="/product-detail/:ref/:subRef" render={props =>
              <ErrorBoundary key='7'>
                <ProductDetail {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref" render={props =>
              <ErrorBoundary key='8'>
                <UnitEquipmentComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref/assets" render={props =>
              <ErrorBoundary key='9'>
                <AssetComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref/assets/gallery" render={props =>
              <ErrorBoundary key='9'>
                <AssetGallery {...props} />
              </ErrorBoundary>
            } />

            <Route path="/request/:ref" render={props =>
              <ErrorBoundary key='10'>
                <RequestsComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/search" render={props =>
              <ErrorBoundary key='11'>
                <SearchComponent {...props} />
              </ErrorBoundary>
            } />
            <Route path="/download" render={props =>
              <ErrorBoundary key='12'>
                <DownloadComponent {...props} />
              </ErrorBoundary>
            } />
            <Route path="/email/:ref" render={props =>
              <ErrorBoundary key='13'>
                <EmailComponent {...props} />
              </ErrorBoundary>
            } />
          </Switch>
        </Fragment>
        {showHeaderFooter && (<Footer />)}
      </div>
    );
  }
}

export const Routes = connect(mapStateToProps)(withRouter(RoutesComponent));
RoutesComponent.contextType = ThemeContext;