const DEFAULT_HEADERS = {
    'Content-Type': 'application/octet-stream; charset=UTF-8',
    'Accept': 'application/octet-stream',
    //'Accept': 'text/html',
    'Accept-Encoding': 'gzip, deflate',
}

import fetch from 'node-fetch';
import {emit} from 'eiphop';
import * as app_constants from '../components/application/constants';

export function fetchMarkdown(url, cb) {
    let reqObj = {
        //method: "GET",
        headers: DEFAULT_HEADERS
    }
    if (process.target != 'web')
    {
        emit(app_constants.FETCH_MARKDOWN_FILE, {uri: url})
            .then(res => { 
                cb(res.content);
            });
    } else {
        return fetch(url, reqObj)
        .then(response => response.text())
        .then(body => { cb(body); })
        .catch(e => { /* fail silently */ });
    }
}