import React, { PureComponent } from 'react';

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 27 22"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={"asset-tab-icon"}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M4.50065081,0.0977257143 C2.01704839,0.0977257143 2.81456026e-07,2.03267651 2.81456026e-07,4.41518603 L2.81456026e-07,12.5104349 C2.81456026e-07,14.8929444 2.01704839,16.8278952 4.50065081,16.8278952 L19.127765,16.8278952 C21.6113675,16.8278952 23.628415,14.8929444 23.628415,12.5104349 L23.628415,4.41518603 C23.628415,2.03267651 21.6113675,0.0977257143 19.127765,0.0977257143 L4.50065081,0.0977257143 Z M4.50065081,1.17709079 L19.127765,1.17709079 C21.0074899,1.17709079 22.5032524,2.61197175 22.5032524,4.41518603 L22.5032524,12.5104349 C22.5032524,14.3136492 21.0074899,15.7485302 19.127765,15.7485302 L4.50065081,15.7485302 C2.62092599,15.7485302 1.12516291,14.3136492 1.12516291,12.5104349 L1.12516291,4.41518603 C1.12516291,2.61197175 2.62092599,1.17709079 4.50065081,1.17709079 Z M9.00130135,4.95486857 L9.00130135,11.9707524 C8.9972145,12.2360423 9.19472716,12.4648402 9.467189,12.5104349 C9.60037559,12.5332918 9.73768801,12.5093421 9.85396366,12.4429746 L16.0423581,8.9350381 C16.2170375,8.83877828 16.3247397,8.66027507 16.3247397,8.4670254 C16.3247397,8.27377572 16.2170375,8.09527251 16.0423581,7.9990127 L9.85396366,4.4910654 C9.75357737,4.43301076 9.63679635,4.4064826 9.519931,4.41518603 C9.22652189,4.43726798 9.00042924,4.67253848 9.00130135,4.95486857 Z M10.126464,5.90773206 L14.6359048,8.46281587 L10.126464,11.0263079 L10.126464,5.90773206 Z"
    />
  </svg>
);

export default SVG;