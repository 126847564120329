var config = {};

const testEnvironment = 'prod'; // local, dev, prod

config.API = {
    url: testEnvironment === 'prod' ? 'dam.readingbakery.com/api' : 'rbs-dam.dev.fuel.digital/api', // prod use prod dam
    user: 'api_user',
    key: 'a61f943bca28ecf1c3639fe3f810c0da6a373bcdbef346781168fe3d67a2d4ae',
    // auth0 passwordless config
    auth0Domain: testEnvironment === 'prod' ? 'auth0.sales.readingbakery.com' : 'dev-cqq82w5a.us.auth0.com',
    auth0ClientID: testEnvironment === 'prod' ? 'lfncNEZEH9DSuigwVeiU1omYcSDbmgWl' : 'm5MQxCMlb5awFumhGBgUGJ4LFubAUSvX',
    auth0RedirectUrl: window.location.origin,
    auth0ResponseType: 'token',
    guestUsername: 'Guest',
    guestPassword: 'hpudbuycgrE9',
    guestKey: testEnvironment === 'prod' ? 'bd03f3e5d1a3b53676449764efe5410ae12dc516fa4bfa57f46a825ce8147f92' : '325b9c72f3ec65a456675a6dc4222b0d70bfccce06deb43cc8f3c7c125530cf1',
};
config.IndexedDB = {
    dbname: 'resources',
    dbversion: 3
};
config.inactivePeriod = 1000 * 60 * 5; // 5 minutes
config.EmailFrom = "dboxey@amfbakery.com";
config.DownloadDir = "/";
config.ResourceSpaceApiKey = "a49c985a074786d251fd08552a810aaf76fe143a9fb72319d977917cc0dbe0bb";
config.ResourceSpaceEmailFrom = "info@readingbakery.com";
config.ResourceSpaceApiUser = "admin";
config.MetadataMapping = {
    clients: [
        {
            name: 'amf',
            metaDataToLoad: 6,
            extraDepth: true,
            unitEquipmentRefsToHide: ['649', '650'], // filter out certain unit equipments in the search page
            singleLevelRefs: [], //Only show breadcrumb links to self and home
            set2SubsystemRefs: ['291', '292', '293'], // move certain subsystems to the Services & Support section in the footer (292 and 293 redirect to the unit equipments themselves)
            refsToHideAssets: ['649', '650'], // filter on click of unit equipment record - passed as prop to Page Header component (title and Assets button). if match, will hide assets button
            redirectRefs: [ // custom redirect rules in subsystem component
                { from: '292', to: '/unitequipment/649' },
                { from: '293', to: '/unitequipment/650' }
            ],
            prodDetailRefs: ['613', '596', '597', '315', '318', '325', '333', '332', '321',
                '327', '334', '316', '472', '314', '319', '322', '324', '336',
                '335'],
            prodAccentImgs: [ // certain images have overlays above the footer
                { ref: '594', count: 3 },
                { ref: '595', count: 3 },
                { ref: '596', count: 3 },
                { ref: '597', count: 3 },
                { ref: '598', count: 3 },
                { ref: '600', count: 3 },
                { ref: '601', count: 3 },
                { ref: '602', count: 2 },
                { ref: '606', count: 3 },
                { ref: '607', count: 3 },
                { ref: '608', count: 2 },
                { ref: '609', count: 3 },
                { ref: '610', count: 2 },
                { ref: '611', count: 2 },
                { ref: '612', count: 3 },
                { ref: '613' }
            ],
            resourceTypes: [ // resource types match - names and ref are different
                {
                    id: 0,
                    name: 'Global',
                    ref: 0
                },
                {
                    id: 1,
                    name: 'Photo',
                    ref: 1
                },
                {
                    id: 2,
                    name: 'Document',
                    ref: 2
                },
                {
                    id: 3,
                    name: 'Video',
                    ref: 3
                },
                {
                    id: 4,
                    name: 'Audio',
                    ref: 4
                },
                {
                    id: 5,
                    name: 'Products',
                    ref: 6
                },
                {
                    id: 6,
                    name: '3D Document',
                    ref: 7
                }
            ],
            validation: {
                validateProductSystemSolutions: true,
                validateBrands: true,
                validateSubsystems: true,
                validateCategories: true,
                validateModels: true,
                validateUnitEquipments: true,
            },
            metadata: [
                {
                    shorthand_name: 'keywords',
                    id: 1
                },
                {
                    shorthand_name: 'country',
                    id: 3
                },
                {
                    shorthand_name: 'title',
                    id: 8
                },
                {
                    shorthand_name: 'extract',
                    id: 9
                },
                {
                    shorthand_name: 'credit',
                    id: 10
                },
                {
                    shorthand_name: 'date',
                    id: 12
                },
                {
                    shorthand_name: 'caption',
                    id: 18
                },
                {
                    shorthand_name: '',
                    id: 25
                },
                {
                    shorthand_name: 'person',
                    id: 29
                },
                {
                    shorthand_name: 'originalfilename',
                    id: 51
                },
                {
                    shorthand_name: 'camera',
                    id: 52
                },
                {
                    shorthand_name: 'source',
                    id: 54
                },
                {
                    shorthand_name: 'text',
                    id: 72
                },
                {
                    shorthand_name: 'framerate',
                    id: 76
                },
                {
                    shorthand_name: 'videobitrate',
                    id: 77
                },
                {
                    shorthand_name: 'aspectratio',
                    id: 78
                },
                {
                    shorthand_name: 'videosize',
                    id: 79
                },
                {
                    shorthand_name: 'duration',
                    id: 80
                },
                {
                    shorthand_name: 'channelmode',
                    id: 81
                },
                {
                    shorthand_name: 'samplerate',
                    id: 82
                },
                {
                    shorthand_name: 'audiobitrate',
                    id: 83
                },
                {
                    shorthand_name: 'productbrand',
                    id: 85
                },
                {
                    shorthand_name: 'sub-system',
                    id: 86
                },
                {
                    shorthand_name: 'unitequipment',
                    id: 88
                },
                {
                    shorthand_name: 'category',
                    id: 89
                },
                {
                    shorthand_name: 'model',
                    id: 90
                },
                {
                    shorthand_name: 'productsystemsolutio',
                    id: 91
                },
                {
                    shorthand_name: 'primaryphoto',
                    id: 92
                }
            ],
            nonKioskFileBlackList: ['md'],
            kioskFileWhiteList: ['']
        },
        {
            name: 'rbs',
            metaDataToLoad: 5,
            extraDepth: false,
            unitEquipmentRefsToHide: ['432', '433', '434', '435', '436'],
            singleLevelRefs: ['432', '433', '434', '435', '436'],
            set2SubsystemRefs: ['427', '428', '429', '430', '431'],
            refsToHideAssets: [],
            redirectRefs: [
                { from: '427', to: '/unitequipment/434' },
                { from: '428', to: '/unitequipment/435' },
                { from: '429', to: '/unitequipment/436' },
                { from: '430', to: '/unitequipment/433' },
                { from: '431', to: '/unitequipment/432' }
            ],
            prodDetailRefs: ['613', '596', '597', '315', '318', '325', '333', '332', '321',
                '327', '334', '316', '472', '314', '319', '322', '324', '336',
                '335', '484', '485', '486', '492', '504'],
            prodDetailLinks: [
                {
                    ref: '472',
                    links: [
                        { type: 'unitequipment', ref: '368' },
                        { type: 'unitequipment', ref: '363' },
                        { type: 'unitequipment', ref: '371' },
                        { type: 'unitequipment', ref: '377' },
                        { type: 'unitequipment', ref: '381' },
                    ]
                }
            ],
            prodAccentImgs: [
                { ref: '315', count: 3 },
                { ref: '325', count: 3 },
            ],
            resourceTypes: [
                {
                    id: 0,
                    name: 'Global',
                    ref: 0
                },
                {
                    id: 1,
                    name: 'Photos',
                    ref: 1
                },
                {
                    id: 2,
                    name: 'Brochures',
                    ref: 2
                },
                {
                    id: 3,
                    name: 'Videos',
                    ref: 3
                },
                {
                    id: 4,
                    name: 'Audio',
                    ref: 4
                },
                {
                    id: 5,
                    name: 'End Products',
                    ref: 5
                },
                {
                    id: 6,
                    name: 'Technical Files',
                    ref: 6
                }
            ],
            validation: {
                validateProductSystemSolutions: true,
                validateBrands: true,
                validateSubsystems: true,
                validateCategories: false,
                validateModels: false,
                validateUnitEquipments: true,
            },
            metadata: [
                {
                    shorthand_name: 'keywords',
                    id: 1
                },
                {
                    shorthand_name: 'country',
                    id: 3
                },
                {
                    shorthand_name: 'title',
                    id: 8
                },
                {
                    shorthand_name: 'extract',
                    id: 9
                },
                {
                    shorthand_name: 'credit',
                    id: 10
                },
                {
                    shorthand_name: 'date',
                    id: 12
                },
                {
                    shorthand_name: 'caption',
                    id: 18
                },
                {
                    shorthand_name: '',
                    id: 25
                },
                {
                    shorthand_name: 'person',
                    id: 29
                },
                {
                    shorthand_name: 'originalfilename',
                    id: 51
                },
                {
                    shorthand_name: 'camera',
                    id: 52
                },
                {
                    shorthand_name: 'source',
                    id: 54
                },
                {
                    shorthand_name: 'text',
                    id: 72
                },
                {
                    shorthand_name: 'subject',
                    id: 73
                },
                {
                    shorthand_name: 'event',
                    id: 74
                },
                {
                    shorthand_name: 'emotion',
                    id: 75
                },
                {
                    shorthand_name: 'framerate',
                    id: 76
                },
                {
                    shorthand_name: 'videobitrate',
                    id: 77
                },
                {
                    shorthand_name: 'aspectratio',
                    id: 78
                },
                {
                    shorthand_name: 'videosize',
                    id: 79
                },
                {
                    shorthand_name: 'duration',
                    id: 80
                },
                {
                    shorthand_name: 'channelmode',
                    id: 81
                },
                {
                    shorthand_name: 'samplerate',
                    id: 82
                },
                {
                    shorthand_name: 'audiobitrate',
                    id: 83
                },
                {
                    shorthand_name: 'productbrand',
                    id: 84
                },
                {
                    shorthand_name: 'sub-system',
                    id: 85
                },
                {
                    shorthand_name: 'productsystemsolutio',
                    id: 86
                },
                {
                    shorthand_name: 'photogroup',
                    id: 87
                },
                {
                    shorthand_name: 'primaryphoto',
                    id: 89
                },
                {
                    shorthand_name: 'unitequipment',
                    id: 90
                },
                {
                    shorthand_name: 'technicalfiletype',
                    id: 91
                },
                {
                    shorthand_name: 'sortorder',
                    id: 92
                }
            ],
            nonKioskFileBlackList: ['md'],
            kioskFileWhiteList: ['png', 'jpg', 'jpeg', 'pdf', 'mp4', 'mov', 'wmv', 'avi', 'avchd', 'mkv', 'webm']
        }
    ]
}

module.exports = config;

