import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SquareLinkComponent from '../ui/square-link-component';
import SecondaryLinkComponent from '../ui/secondary-link-component';
import { ThemeContext } from '../../theme-context';
import { sortByMetadata } from '../../helpers/sort';

const mapStateToProps = state => ({
    application: state.Application
});

class Application extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderProductRow(products) {
        let { theme } = this.context;
        return (<div className={`product-row product-row--${theme}`}>
            <div className={`product-row-inner product-row-inner--${theme}`}>
            {products.map(p => <SquareLinkComponent key={p.ref} title={p.name} uri={`solutions/${p.ref}`} imageUri={``} classRef={p.ref} />)}
        </div>
        </div>);
    }

    renderSubsystemRow(application,subsystems,theme) {
        switch (application.currentClient) {
            case 'amf':
                // do nothing - no filtering
                break;
            case 'rbs':
                // filter out certain subsystems (will be placed in footer instead)
                subsystems = subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) === -1);
                break;
        }

        return (<div className={`buttons-below-images-row1 buttons-below-images-row1--${theme}`}>
                    <div className={`buttons-below-images-row2 buttons-below-images-row2--${theme}`}>
                        {subsystems.map(s => <SecondaryLinkComponent key={s.ref} title={s.name} uri={`subsystems/${s.ref}`} classRef={s.ref}  />)}
                    </div>
                </div>);
    }

    renderSearchButton(){
        let { application } = this.props;
        let { theme } = this.context;
        switch (application.currentClient) {
            case 'amf':
                return (<Link to='/search'>
                <button type="button" className={`btn btn--${theme} btn-square btn-md btn-outline-light`}>
                Search Unit Equipment <i style={{ paddingRight: '2px' }} className="fa fa-caret-right fa-caret-right--amf"></i>
                </button>
                </Link> )
            case 'rbs':
                return (<Link to='/search'>
                <button type="button" className={`btn btn--${theme} btn-square btn-md`}><img className="rbs-search-icon" src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='23px' height='25px' viewBox='0 0 23 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3ESearch%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Home' transform='translate(-596.000000, -937.000000)' fill='%23FFFFFF' fill-rule='nonzero' stroke='%23FFFFFF'%3E%3Cg id='Group-11' transform='translate(580.000000, 890.000000)'%3E%3Cg id='Group-10' transform='translate(0.000000, 36.000000)'%3E%3Cpath d='M24.868188,12 C20.5291349,12 17,15.8360103 17,20.5523657 C17,25.2687299 20.5291349,29.1047314 24.868188,29.1047314 C26.8438613,29.1047314 28.6490605,28.3052533 30.0316864,26.9942877 L37.2385396,34.8278123 C37.4390525,35.0521792 37.8077363,35.0593897 38.0100969,34.8370282 C38.2124572,34.6146372 38.2102348,34.204625 38.0016182,33.9891644 C32.400071,29.7744712 30.0006128,27.1663684 30.8032436,26.1648557 C32.0071898,24.6625866 32.736376,22.6976974 32.736376,20.5523657 C32.736376,15.8360103 29.2072411,12 24.868188,12 Z M24.868188,13.1796366 C28.620719,13.1796366 31.6511087,16.4735331 31.6511087,20.5523657 C31.6511087,24.6311953 28.620719,27.9250947 24.868188,27.9250947 C21.1156571,27.9250947 18.0852673,24.6311953 18.0852673,20.5523657 C18.0852673,16.4735331 21.1156571,13.1796366 24.868188,13.1796366 Z' id='Search'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E" />
                SEARCH UNIT EQUIPMENT
                </button>
                </Link> )
            }
    }

    render() {
        let { application } = this.props;
        let { theme } = this.context;
        let ignoredSubsystems = ['291', '292', '293'];
        let products = application.solutions.filter(s => s.parent == '');
        products.sort(sortByMetadata);
        let subsystems = application.subsystems.filter(s => s.parent == '' && ignoredSubsystems.indexOf(s.ref) === -1)
        subsystems.sort(sortByMetadata);
        return (
            <div>
                {this.renderProductRow(products, theme)}
                {this.renderSubsystemRow(application,subsystems,theme)}
                <div className={`row center-question-row center-question-row--${theme} hide-mobile`}>
                    <div className="col-12 text-center">
                        <h3 className={`center-question center-question--${theme}`}>Know what you're looking for?</h3>
                    </div>
                    <div className="col-12 text-center unit-equipment-button">
                        {this.renderSearchButton()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(Application));
Application.contextType = ThemeContext;