import React, { PureComponent } from 'react';

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 25 25"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={"asset-tab-icon"}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M21.9418944,0.0344108446 L14.9261384,3.20020855 L7.8632969,0.0344108446 C7.8632969,0.0344108446 7.8632969,0.0344108446 7.81621129,0.0344108446 C7.67495446,-0.0114702815 7.58078324,-0.0114702815 7.43952641,0.0344108446 L0.282513661,3.2919708 C0.0941712204,3.38373306 0,3.52137643 0,3.70490094 L0,21.552659 C0.0941712204,21.919708 0.282513661,22.0573514 0.659198543,21.9655892 L7.67495446,18.7997914 C7.67495446,18.7997914 14.737796,21.9655892 14.737796,21.9655892 C14.8319672,22.0114703 14.973224,22.0114703 15.0673953,21.9655892 C15.0673953,21.9655892 22.3185792,18.7080292 22.3185792,18.7080292 C22.4598361,18.6162669 22.6010929,18.4786236 22.6010929,18.2950991 L22.6010929,0.44734098 C22.5069217,0.0802919708 22.3185792,-0.0573514077 21.9418944,0.0344108446 Z M7.20409836,18.0198123 L0.941712204,20.8644421 L0.941712204,3.9801877 L7.25118397,1.13555787 L7.25118397,18.0198123 L7.20409836,18.0198123 Z M14.4552823,20.8644421 L8.14581056,18.0198123 L8.14581056,1.181439 L14.4552823,4.02606882 L14.4552823,20.8644421 L14.4552823,20.8644421 Z M21.6593807,18.0198123 L15.3499089,20.8644421 L15.3499089,3.9801877 L21.6593807,1.181439 L21.6593807,18.0198123 Z"
    />
  </svg>
);

export default SVG;
