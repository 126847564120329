import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

const mapStateToProps = state => ({
    application: state.Application
});

class SquareLinkComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderSquareLinks() {
        let {title, uri, classRef, application } = this.props;
        let { theme } = this.context;

        switch (application.currentClient) {
            case 'amf':
                return (
                    <div className={`product-row-item product-row-item-${classRef} product-row-item--${theme}`} style={{backgroundImage:`url(./img/product-${classRef}-square.jpg)`}}>
                    <Link to={uri} className={`product-row-item-content product-row-item-content--${theme}`}>
                        <div className={`product-row-item-content-inner product-row-item-content-inner--${theme}`}>
                            <div className={`product-row-item-content-inner-margin product-row-item-content-inner-margin--${theme}`}>
                            <h5 className={`product-row-text product-row-text--${theme}`}>{title}</h5>
                            </div>
                        </div>
                    </Link>
                </div>)
            case 'rbs':
                return (
                    <div className={`product-row-item product-row-item-${classRef} product-row-item--${theme}`}>
            <Link to={uri} className={`product-row-item-content product-row-item-content--${theme}`}>
            <img className={`product-row-image product-row-image-${classRef} product-row-image--${theme}`}  src={`./img/product-${classRef}-square-rbs.png`} />
                <div className={`product-row-item-content-inner product-row-item-content-inner--${theme}`}>
                    <div className={`product-row-item-content-inner-margin product-row-item-content-inner-margin--${theme}`}>
                    <h5 className={`product-row-text product-row-text--${theme}`}>{title}</h5>
                    </div>
                </div>
            </Link>
        </div>)
                    
        }
    }

    render() {
        return(
                 this.renderSquareLinks()
        );
    }
}

export default withRouter(connect(mapStateToProps)(SquareLinkComponent));
SquareLinkComponent.contextType = ThemeContext;