import ApiResource from '../apiResource';
import ApiMethod from '../apiMethod';

module.exports = class Email extends ApiResource {
    constructor(api) {
        super(api);

        this.sendEmailWithAttachment = ApiMethod({
            method: 'GET',
            function: 'send_email_with_attachment',
            urlParams: ['param1', 'param2', 'param3'],
            params: {
                param1: '',
                param2: '',
                param3: ''
            }
        })
    }
}