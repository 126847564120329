import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {ThemeContext, themes} from './theme-context';

import { store } from "./store.js";
import { router } from "./router.js";

import { setupFrontendListener } from 'eiphop';
const electron = window.electron;

function setupElectron(electron) {
    console.log('Remote: ' + electron);
    if(electron)
        setupFrontendListener(electron);
    else
        setTimeout(() => {setupElectron(window.electron)}, 1000)
}

if (process.target != 'web')
{
    setupElectron(electron);
}

import "./main.scss";
class App extends React.Component {
    state = {
        theme: process.vendor //TODO: Pull from config
    }
    constructor(props) {
      super(props);
    }
    render() {
        if(!process.vendor) {
            return (<h1>No Vendor Supplied. Please Check Configuration.</h1>)
        }
        return (<ThemeContext.Provider value={this.state}>
            <Provider store={store}>
                {router}
            </Provider>
        </ThemeContext.Provider>);
    }
}


//Load the main React App
let amfApp = document.getElementById("amf-app");
if (amfApp) {
    ReactDOM.render(
        <App />,
        amfApp
    );
}

