import React from "react";
import { HashRouter, withRouter } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import { Routes } from "./App";
import { store, history } from "./store";
import ScrollToTop from './components/ui/scroll-to-top-component';

const syncedHistory = syncHistoryWithStore(history, store)
var RoutesSync = withRouter(Routes) // IMPORTANT: use withRouter()

// build the router
const router = (
  <HashRouter>
    <ScrollToTop>
      <RoutesSync />
    </ScrollToTop>
  </HashRouter >
);

// export
export { router };