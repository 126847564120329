import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ThemeContext } from '../../theme-context';

const mapStateToProps = state => ({
    application: state.Application
});

class UnitEquipmentSmallComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        
        let {id, name, imageUrl, application} = this.props;
        let { theme } = this.context;
        return(<div className={`related-equipment-box related-equipment-box--${theme}`}>
            <Link to={`/unitequipment/${id}`}>
                <div className={`related-equipment-img related-equipment-img--${theme}`} style={{backgroundImage:`url(${imageUrl})`}}>
                </div>
                <div className={`related-equipment-textbox related-equipment-textbox--${theme}`}>
                    <h5 className={`related-equipment-text related-equipment-text--${theme}`}>{name}</h5>
                </div>
            </Link>
        </div>);
    }
}

export default withRouter(connect(mapStateToProps)(UnitEquipmentSmallComponent));
UnitEquipmentSmallComponent.contextType = ThemeContext;
