import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import LoaderUI from '../ui/loader/component';

const DocumentComponent = ({ fileUri, width }) => {
    const [numPages, setNumPages] = useState(0);

    function createPDFPages(_numPages) {
        let pages = [];

        for (var i = 0, l = _numPages; i < _numPages; i++) {
            pages.push(<Page pageNumber={i + 1} width={width} key={`page${i}`} />);
        }

        return pages;
    };

    return (<Document
        file={fileUri}
        onLoadSuccess={({numPages}) => setNumPages(numPages)}
        onSourceSuccess={e => {
            //console.log(e);
        }}
        loading={<LoaderUI hidePercentageBar={true} hideLoadingText={true} />}
    >
        {createPDFPages(numPages)}
    </Document>)
}

export default DocumentComponent
