import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import BreadcrumbsComponent from '../ui/breadcrumbs';
import BackButtonComponent from '../ui/back-button-component';
import SquareLinkComponent from '../ui/square-link-component';
import { sortByMetadata } from '../../helpers/sort';
import { ThemeContext } from '../../theme-context';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
    application: state.Application
});

class Solutions extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.checkForRedirect(this.props.application, this.props.match, this.props.history)
    }

    componentDidUpdate(oldProps) {
        if(this.props.match && oldProps.match && this.props.match.params.ref !== oldProps.match.params.ref) {
            this.checkForRedirect(this.props.application, this.props.match, this.props.history);
        }
    }

    checkForRedirect(application, match, history) {
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        const solutions = application.solutions.filter(s => s.parent == ref);
        if(solutions.length === 1) { history.replace(`/product-detail/${solutions[0].ref}/1`); }
    }

    renderProductRow(products, productDetailRefs) {
        return (
            <div className="product-row">
                {products.map(p => <SquareLinkComponent key={p.ref} title={p.name} uri={ productDetailRefs.find(r => p.ref === r) ? `/product-detail/${p.ref}/1` : `/products/${p.ref}`} imageUri={``} classRef={p.ref} />)}
            </div>
        );
    }

    render() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        const solutions = application.solutions.filter(s => s.parent == ref);
        const solution = application.solutions.find(s => s.ref == ref);
        solutions.sort(sortByMetadata);
        let { theme } = this.context;
        
        let links = [
            {
                title: 'Home',
                url: '/'
            },
            {
                title: `${solution.name}`,
                url: `/solutions/${solution.ref}`
            }
        ];

        return (
            <div className="solutions">
                <BreadcrumbsComponent links={links} />

                <div className="inner-width">
                    <BackButtonComponent />
                    <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{solution.name}</h1>
                    {this.renderProductRow(solutions, application.prodDetailRefs)}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(Solutions));
Solutions.contextType = ThemeContext;
