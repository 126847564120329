import { createStore, applyMiddleware, compose } from "redux";
import { reducers } from "./reducers/index";
import { createHashHistory } from "history";
import thunk from 'redux-thunk';
//import  epics from './epics/index';
import { routerMiddleware } from 'react-router-redux';
//import { forwardToMain, replayActionRenderer, getInitialStateRenderer } from 'electron-redux';
//import { electronEnhancer } from 'redux-electron-store';

// create history
export const history = createHashHistory();

// add the middlewares 
let middlewares = [];
let enhancers = [];

// create router middleware
const router = routerMiddleware(history);
//if(process.target == 'electron-renderer')
//middlewares.push(forwardToMain);

middlewares.push(thunk);
middlewares.push(router);

// apply the middleware
//enhancers.push(applyMiddleware(...middlewares));
//enhancers.push(electronEnhancer({ dispatchProxy: a => store.dispatch(a)}))

//const enhancer = composeEnhancers(...enhancers);

// get any data from localStorage
// const getFromLocalStorage = () => {
//     let initialStore = { Application: null};
//     let initialValues = { userId: null, token: null };
//     try {
//         let userInfo = localStorage.getItem('userInfo');
//         initialStore.Application = userInfo ? JSON.parse(userInfo) : initialValues;  
//     } catch (err) {
//         initialStore.Application = initialValues;
//     }
//     return initialStore;
// }


// create the store
export const store = createStore(reducers, applyMiddleware(...middlewares));

//if(process.target == 'electron-renderer')
//replayActionRenderer(store);

//export default store;