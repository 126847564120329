import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

class BreadcrumbComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        let {links} = this.props;
        let { theme } = this.context;
        return(<nav aria-label="breadcrumb">
            <ol className={`breadcrumb breadcrumb--${theme} arr-right arr-right--${theme}`}>
                {links.map((l, i) => {
                    let classes = (i == links.length-1) ? `breadcrumb-item breadcrumb-item--${theme} active active--${theme}` : "breadcrumb-item"
                    return (<li key={`breadcrumb${i}`} className={classes}><Link to={l.url}>{l.title}</Link></li>);
                })}
            </ol>
        </nav>);
    }
}

export default withRouter(BreadcrumbComponent);
BreadcrumbComponent.contextType = ThemeContext;