//const fetch = (process.target == 'web') ? require('node-fetch') : require('electron-fetch');
import fetch from 'node-fetch';
//import { URLSearchParams } from 'url';
import sjcl from 'sjcl';

var maxRequestAttempts = 10;

export function createRequest(api, params, args) {
    let protocol = api._getApiField("protocol");
    let host = api._getApiField("host");
    let headers = api._getApiField("headers");
    let path = `${buildBaseUrl(protocol, host)}`;
    //let commandPath = makeURLInterpolator()(path);
    //let url = commandPath(args);


    let reqObj = {
        method: params.method,
        headers: headers
    }
    let searchParams = new URLSearchParams();
    searchParams.append("user", api._getApiField("user"));
    searchParams.append("function", params.function);
    //Loop through param args
    for(let i = 1; i < 10; i++) {
        if(args['param' + i] != undefined) {
            searchParams.append(`param${i}`, args['param'+i]);
        }
    }
    let baseQuery = searchParams.toString();
    let sha256BitArray = sjcl.hash.sha256.hash(api._getApiField("key") + baseQuery);
    let sha256Digest = sjcl.codec.hex.fromBits(sha256BitArray);
    searchParams.append("sign", sha256Digest);
    let statusCode = 999;
    return fetch(path+'/?'+searchParams.toString(),reqObj)
      .then(res => {
        statusCode = res.status;
        return res.json();
      })
      .then(json => {
         if(statusCode >= 400) {
            return {
              error: {
                statusCode: statusCode,
                message: json
              }
            }
         } else {
           return json;
         }
      });
}

function buildBaseUrl(protocol, host) {
    if (protocol == "https") {
        return `${protocol}://${host}`;
    } else {
        return `${protocol}://${host}`;
    }
}

/*
*   Use like so:
*   var fn = makeURLInterpolator('some/url/{param1}/{param2}');
*   fn({ param1: 123, param2: 456 }); // => 'some/url/123/456'
*/
function makeURLInterpolator() {
    var rc = {
      '\n': '\\n', '\"': '\\\"',
      '\u2028': '\\u2028', '\u2029': '\\u2029',
    };
    return function makeURLInterpolator(str) {
      var cleanString = str.replace(/["\n\r\u2028\u2029]/g, function($0) {
        return rc[$0];
      });
      return function(outputs) {
        return cleanString.replace(/\{([\s\S]+?)\}/g, function($0, $1) {
          return encodeURIComponent(outputs[$1] || '');
        });
      };
    };
}