import React, { PureComponent } from 'react';

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 25 22"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={"asset-tab-icon"}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M22.8900273,0.11875 L0.738387978,0.11875 C0.393806922,0.11875 0.123064663,0.38 0.123064663,0.7125 L0.123064663,18.2875 C0.123064663,18.62 0.393806922,18.88125 0.738387978,18.88125 L22.8900273,18.88125 C23.2346084,18.88125 23.5053506,18.62 23.5053506,18.2875 L23.5053506,0.7125 C23.5053506,0.38 23.2346084,0.11875 22.8900273,0.11875 Z M22.2500911,1.33 L22.2500911,14.0125 L18.8535064,10.4025 C18.1643443,9.69 16.8844718,9.69 16.1953097,10.4025 L14.5462432,12.13625 L9.77133424,6.84 C9.40214026,6.43625 8.88526867,6.2225 8.31917122,6.2225 C8.31917122,6.2225 8.31917122,6.2225 8.31917122,6.2225 C7.7776867,6.2225 7.23620219,6.46 6.89162113,6.86375 L1.37832423,12.92 L1.37832423,1.33 L22.2500911,1.33 Z M1.37832423,17.67 L1.37832423,14.74875 L7.82691257,7.62375 C7.94997723,7.48125 8.12226776,7.41 8.31917122,7.41 C8.49146175,7.41 8.71297814,7.48125 8.83604281,7.62375 L14.0785974,13.4425 C14.2016621,13.56125 14.3739526,13.65625 14.5462432,13.65625 C14.7431466,13.68 14.8908242,13.585 15.0138889,13.46625 L17.1306011,11.23375 C17.3275046,11.02 17.7213115,11.02 17.9428279,11.23375 L22.274704,15.8175 L22.274704,17.69375 L1.37832423,17.69375 L1.37832423,17.67 Z"
    />
    <path
     fill={fill}
     d="M17.7213115,7.95625 C19.0257969,7.95625 20.1087659,6.935 20.1087659,5.6525 C20.1087659,4.37 19.0504098,3.34875 17.7213115,3.34875 C16.3922131,3.34875 15.333857,4.37 15.333857,5.6525 C15.333857,6.935 16.416826,7.95625 17.7213115,7.95625 Z M17.7213115,4.56 C18.3366348,4.56 18.8535064,5.05875 18.8535064,5.6525 C18.8535064,6.24625 18.3366348,6.745 17.7213115,6.745 C17.1059882,6.745 16.5891166,6.24625 16.5891166,5.6525 C16.5891166,5.05875 17.1059882,4.56 17.7213115,4.56 Z"
     />
  </svg>
);

export default SVG;
