Api.DEFAULT_PROTOCOL = 'https';
Api.DEFAULT_HOST = 'amf-dam.dev.fuel.digital/api';
Api.DEFAULT_PORT = '80';
Api.DEFAULT_TIMEOUT = 300; //require('http').createServer().timeout;
Api.DEFAULT_HEADERS = {
    'Content-Type': 'text/html; charset=UTF-8',
    'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    //'Accept': 'text/html',
    'Accept-Encoding': 'gzip, deflate',
}

var resources = {
    Metadata: require('./resources/metadata'),
    Search: require('./resources/search'),
    Resources: require('./resources/resource'),
    User: require('./resources/user'),
    Email: require('./resources/email')
}

Api.resources = resources;

function Api(user, key, url) {
    if(!(this instanceof Api)) {
        return new Api(user, key, url);
    }

    this._api = {
        auth: null,
        protocol: Api.DEFAULT_PROTOCOL,
        host: Api.DEFAULT_HOST,
        port: Api.DEFAULT_PORT,
        headers: Api.DEFAULT_HEADERS,
        timeout: Api.DEFAULT_TIMEOUT,
        maxNetworkRetries: 0
    }

    this._prepResources();
    this.setApiUser(user);
    this.setApiKey(key);
    this._setApiField("host", url);

    //this.errors = require('./Error');
}

Api.prototype = {
    setApiUser: function(user) {
        this._setApiField('user', user);
    },
    setApiKey: function(key) {
        this._setApiField('key', key);
    },
    _setApiField: function(key, value) {
        this._api[key] = value;
    },
    _getApiField: function(key) {
        return this._api[key];
    },
    _prepResources: function() {
        for(var name in resources) {
            this[name.toLowerCase()] = new resources[name](this);
        }
    }
};

module.exports = Api;