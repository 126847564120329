import ApiResource from '../apiResource';
import ApiMethod from '../apiMethod';

module.exports = class Resource extends ApiResource {
    constructor(api) {
        super(api);

        this.getResourceFieldData = ApiMethod({
            method: 'GET',
            function: 'get_resource_field_data',
            urlParams: ['param1'],
            params: {
                param1: '', //REQUIRED; resource.ref
            }
        })

        this.getResourcePath = ApiMethod({
            method: 'GET',
            function: 'get_resource_path',
            urlParams: ['param1', 'param2', 'param3','param5'],
            params: {
                param1: '', //REQUIRED; resource.ref
                param2: '', //REQUIRED; File path returned or URL? (true = file path)
                param3: '', //File size ['thm','scr',etc] Leave blank for original file
                param4: '',
                param5: '', //File extension ['jpg','mp4','pdf'] defaults to 'jpg'
                param6: '',
                param7: '',
                param8: ''
            }
        })

    }
}